export const PERFIL = {
    ADMINISTRADOR: 'administrador',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
    ADMINISTRADOR_LOCAL: 'adminLocal',
    ADMINISTRADOR_TELEMEDICINA: 'adminTelemedicina',
    ADMINISTRADOR_MUNICIPAL: 'adminMunicipal',
    MEDICO_EXECUTOR: 'medicoExecutor',
    MEDICO_LAUDADOR: 'medicoLaudador',
    MEDICO_RESIDENTE: 'medicoResidente',
    MEDICO_SOLICITANTE: 'medicoSolicitante',
    MEDICO_VISUALIZADOR: 'medicoVisualizador',
    REGULADOR_EXAME: 'reguladorExame',
    TECNICO: 'tecnico',
    VISUALIZADOR_REDE: 'visualizadorRede'
};

export const SIM = 'S';
export const NAO = 'N';

export const VINCULO_PERFIL = {
    GLOBAL: 'G',
    INSTITUICAO: 'I',
    REDE: 'R',
    USUARIO: 'U',
    MUNICIPAL: 'M'
};

export const PERMISSOES = {
    ACESSAR_HISTORICO_EXAMES: 'EXAMES_ACESSAR_HIST_EXAMES',
    ADICIONAR_ANEXO: 'EXAMES_ADICIONAR_ANEXO',
    CADASTRAR_MODELO_LAUDO: 'EXAMES_CAD_MODELO_LAUDO',
    DIRECIONAR_EXAME: 'EXAMES_DIRECIONAR_EXAME',
    DOWNLOAD_EXAME: 'EXAMES_DOWNLOAD_EXAME',
    EXAMES: 'EXAMES_EXAMES',
    EXCLUIR_MODELO_LAUDO: 'EXAMES_EXCLUIR_MODELO_LAUDO',
    HISTORICO_PACIENTE: 'EXAMES_HISTORICO_PACIENTE',
    INSERIR_INDICACAO_CLINICA: 'EXAMES_INSERIR_IND_CLINICA',
    INVALIDAR_EXAME: 'EXAMES_INVALIDAR_EXAME',
    LAUDAR_EXAME: 'EXAMES_LAUDAR_EXAME',
    VISUALIZ_INDICACAO_CLINICA: 'EXAMES_VISUALIZ_IND_CLINICA',
    VISUALIZAR_EXAME: 'EXAMES_VISUALIZACAO',
    VISUALIZAR_IMAGEM: 'EXAMES_VISUALIZAR_IMAGEM',
    VISUALIZAR_MODELO_LAUDO: 'EXAMES_VIS_MODELO_LAUDO'
};

export const MODALIDADE = {
    DERMATOLOGIA: 'VLP',
    ELETROCARDIOGRAMA: 'ECG',
    ELETROENCEFALOGRAFIA: 'EEG',
    ESPIROMETRIA: 'ESP',
    ESTOMATOLOGIA: 'EST',
    PATOLOGIA: 'BP',
    POLISSONOGRAFIA: 'PSG',
    RADIOLOGIA: 'CR'
};

export const ORIGEM_MANUAL = 'M';

export const TIPO_USUARIO = {
    EXTERNO: 'EXTERNO',
    INTERNO: 'INTERNO'
};

export const TIPO_ANEXO = {
    BMP: 'bmp',
    PDF: 'pdf'
};

export const CONSTANTE_TIPO_BASE_BANCO = 'BD';
export const CONSTANTE_TIPO_BASE_MINIO = 'MINIO';
export const CONSTANTE_TIPO_BASE_PACS = 'PACS';

export const VISUALIDADOR_DICOM = {
    OHIF: 'OHIF',
    OVIYAM: 'OVIYAM'
};

export const ACESSO_WEASIS = {
    DOWNLOAD: 'W',
    INSTALADO: 'L'
};

export const MODALIDADE_HISTORICO_EXAME = [
    'CR'
];

export const DESCRICAO_TIPO_MODALIDADE_TELEDIAGNOSTICO = 'Telediagnóstico';

export const MODALIDADES_TELEDIAGNOSTICO = [
    MODALIDADE.ELETROCARDIOGRAMA,
    MODALIDADE.ELETROENCEFALOGRAFIA,
    MODALIDADE.DERMATOLOGIA,
    MODALIDADE.ESPIROMETRIA,
    MODALIDADE.ESTOMATOLOGIA,
    MODALIDADE.POLISSONOGRAFIA
];

export const ASSINATURA_DIGITAL = {
    NEOID: {
        ALGORITMO: {
            SHA256: '2.16.840.1.101.3.4.2.1',
            SHA512: '2.16.840.1.101.3.4.2.3'
        },
        AUTHORIZATION_CODE: 'authorization_code',
        AUTHORIZATION_TOKEN: 'neoid-authorization-token',
        CODE_VERIFIER: 'neoid-code-verifier',
        CONFIG: 'neoid-config',
        ESCOPO: {
            MULTI_SIGNATURE: 'multi_signature',
            SIGNATURE_SESSION: 'signature_session',
            SINGLE_SIGNATURE: 'single_signature'
        },
        FORMATO: {
            CMS: 'CMS',
            RAW: 'RAW'
        },
        STATE: 'stt-app-state'
    }
};

export const SITUACAO_LAUDO = {
    COM_LAUDO: 'S',
    TEMPORARIO: 'T',
    SEM_LAUDO: 'N',
    EM_EMISSAO: 'E',
    DIGITADO: 'D',
    COM_LAUDO_APLICATIVO: 'M',
    AGUARDANDO_COMPLEMENTO: 'A',
    AGUARDANDO_TRANSCRICAO: 'I'
};
