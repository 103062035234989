import React, { useState, useEffect, useContext } from 'react';
import { Field } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import { SttAutocomplete, SttTranslateHook } from '@stt-componentes/core';
import { MODALIDADE } from '../fieldNames';

const Modalidade = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [modalidades, setModalidades] = useState([]);
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

    useEffect(() => {
        axios.get(`${UTILITARIOS_API_BASE_URL}/modalidade`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setModalidades(itens);
                }
            })
            .catch(err => console.log(err));
    }, []);
    
    return (
        <Field name={MODALIDADE}>
            {({
                field: { name, value, onBlur },
                form: { setFieldValue }
            }) => (
                <SttAutocomplete
                    inputprops={{
                        name: name,
                        label: strings.modalidade
                    }}
                    getOptionLabel={option => option?.descricao || ''}
                    getOptionSelected={(option, val) => option?.id === val?.id}
                    options={modalidades}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e, item) => {
                        setFieldValue(MODALIDADE, item || null);
                    }}
                />
            )}
        </Field>
    );
};

export default Modalidade;