import TYPES from '../types/assinatura-digital';

export const setConfig = (config) => {
    return {
        type: TYPES.SET_CONFIG,
        payload: { config }
    };
}

export const setScope = (scope) => {
    return {
        type: TYPES.SET_SCOPE,
        payload: { scope }
    };
}

export const clearScope = () => {
    return {
        type: TYPES.CLEAR_SCOPE
    };
}

export const setTokenTimestamp = (timestamp) => {
    return {
        type: TYPES.SET_TOKEN_TIMESTAMP,
        payload: { timestamp }
    };
}

export const clearTokenTimestamp = () => {
    return {
        type: TYPES.CLEAR_TOKEN_TIMESTAMP
    };
}