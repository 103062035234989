import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import StickyBox from "react-sticky-box";
import { connect } from 'react-redux';
import { setAtualizarBusca as setAtualizarBuscaAction } from '../../reducers/actions/exame';
import axios from 'axios';
import { getHeaders } from '../../request';
import Form from './form';
import ImageViewer from '../image-viewer';
import Invalidar from '../exame/invalidar';
import Download from '../exame/download';
import Oviyam from '../image-viewer/oviyam';
import Weasis from '../image-viewer/weasis';
import { useMoment } from '../../hooks';
import { SITUACAO_LAUDO } from '../exame/constantes';
import { SttGrid, SttAlerta, SttButton, SttHidden, SttTranslateHook } from '@stt-componentes/core';
import { NAO, SIM } from '../../common/Constants';

const useStyles = makeStyles(theme => ({
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: theme.spacing(2),
        margin: theme.spacing(2)
    },
    tabs: {
        padding: 0
    }
}));

const ConteinerLaudo = (props) => {
    const { dados: { idExame }, urlOhif, setAtualizarBusca } = props;

    const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const moment = useMoment();
    const history = useHistory();

    const [exame, setExame] = useState(null);
    const [invalidar, setInvalidar] = useState(false);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [anexos, setAnexos] = useState([]);
    const [token, setToken] = useState(null);
    const [esconderVisualizadorImagem, setEsconderVisualizadorImagem] = useState(false);
    const [fecharOhif, setFecharOhif] = useState(false);

    const refJanelaVisualizador = useRef();
    /**
     * Carrega os dados do exame
     */
    useEffect(() => {
        document.body.style.removeProperty('overflow');
        if (idExame) {
            // Carrega o exame
            axios
                .get(`${EXAMES_API_BASE_URL}/laudo/${idExame}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        let { data } = response.data;
                        let dataMoment = moment(data.data_nascimento_paciente);
                        if (dataMoment.isValid()) {
                            var now = moment();
                            var dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
                            var idade = moment.duration(now.diff(dataMomentFormatada));
                            var anos = idade.years();
                            var meses = idade.months();
                            var dias = idade.days();
                            data.idade_paciente = ` ${anos} ano(s), ${meses} mes(es) e ${dias} dia(s)`
                        }
                        setExame(data);
                        setToken(data.token_pacs);
                    }
                })
                .catch(err => {
                    console.log(err);
                    const { response } = err;
                    let msg = strings.erroDesconhecido;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }
                    setOpcoesAlerta([{
                        title: strings.ok,
                        onClick: () => voltar(true)
                    }]);
                    setMensagemErro(msg);
                    setErro(true);
                });

            // Carrega lista de arquivos anexos
            axios
                .get(`${EXAMES_API_BASE_URL}/exame/${idExame}/anexo`, { headers: getHeaders() })
                .then((response) => {
                    const { data } = response;
                    setAnexos(data);
                })
                .catch(err => {
                    console.log(err);
                });
        }

        return () => {
            if (refJanelaVisualizador.current) {
                refJanelaVisualizador.current.close();
            }
        }
    }, []);

    useEffect(() => {
        if (exame?.possui_imagem === NAO) {
            setEsconderVisualizadorImagem(true);
        }
    }, [exame]);

    const destacarOhif = () => {
        let url = urlOhif[exame.id].replace('<StudyInstanceUID>', exame.studyinstanceuid);
        if (exame.url_base_pacs?.includes('dcm4chee-arc')) {
            url = url.replace('<token>', token);
        } else {
            url = url.replace('?access_token=<token>', '');
        }

        const ohif = window.open(
            url,
            exame.id,
            `status=no,resizable=no,scrollbars=no,location=no,menubar=no,toolbar=no,width=${(window.screen.width * 0.8)},height=${(window.screen.height * 0.8)}`
        );
        destacarVisualizador(ohif);
    }

    const destacarVisualizador = (viewer) => {
        refJanelaVisualizador.current = viewer;
        setFecharOhif(true);
    }

    const voltar = async (cancelarLaudo) => {
        if (cancelarLaudo) {
            await cancelarEmissao(idExame);
        }
        setAtualizarBusca(true);
        history.goBack();
    }

    const cancelarEmissao = async (idExame) => {
        await axios
            .put(`${EXAMES_API_BASE_URL}/cancelar-laudo`, { idExame }, { headers: getHeaders() })
            .catch(err => console.log(err));
    };


    return (
        !!exame &&
        <>
            <SttGrid container spacing={3}>
                <SttGrid item xs={12} className={classes.wrapperBotao}>
                    {
                        exame.possui_imagem === SIM &&
                        <>
                            {/* VISUALIZADOR WEASIS */}
                            <Weasis exame={exame} token={token} />

                            {/* VISUALIZADOR OHIF */}
                            <SttButton
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={() => setFecharOhif(false)}
                            >
                                {strings.ohif}
                            </SttButton>

                            {/* VISUALIZADOR OVIYAM */}
                            <Oviyam exame={exame} callback={destacarVisualizador} />

                            {/* DOWNLOAD DICOM */}
                            <Download exame={exame} />
                        </>
                    }
                    {
                        exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO &&
                        <SttButton
                            variant="contained"
                            danger={+true}
                            onClick={() => setInvalidar(true)}
                        >
                            {strings.invalidar}
                        </SttButton>
                    }

                    <SttButton
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() => voltar(true)}
                    >
                        {strings.voltar}
                    </SttButton>

                </SttGrid>
            </SttGrid>
            <SttGrid container spacing={3}>
                <SttHidden mdUp>
                    <SttGrid item xs={12}>
                        <ImageViewer
                            anexos={anexos}
                            token={token}
                            exame={exame}
                        />
                    </SttGrid>
                    <SttGrid item xs={12}>
                        <Form exame={exame} />
                    </SttGrid>
                </SttHidden>
                <SttHidden only={['xs', 'sm']}>
                    {
                        !fecharOhif &&
                        <SttGrid item xs={8}>
                            <StickyBox>
                                <ImageViewer
                                    anexos={anexos}
                                    token={token}
                                    exame={exame}
                                    fecharFn={setFecharOhif}
                                    destacarFn={destacarOhif}
                                    botoes={true}
                                />
                            </StickyBox>
                        </SttGrid>
                    }
                    <SttGrid item xs={!fecharOhif ? 4 : 12}>
                        <Form exame={exame} />
                    </SttGrid>
                </SttHidden>
            </SttGrid>
            {
                invalidar &&
                <Invalidar
                    invalidar={invalidar}
                    setInvalidar={setInvalidar}
                    idExame={idExame}
                    callback={voltar}
                    modalidade={exame.sigla_modalidade}
                />
            }
            <SttAlerta
                open={erro}
                title={strings.erro}
                message={mensagemErro}
                type="error"
                options={opcoesAlerta}
                onClose={() => voltar(true)}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        urlOhif: state.exame.urlOhif
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAtualizarBusca: atualizar => dispatch(setAtualizarBuscaAction(atualizar))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConteinerLaudo);
