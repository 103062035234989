import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import BotaoPublicar from './publicacao';

import { 
    SttModal,
    SttButton,
    SttTranslateHook
} from '@stt-componentes/core';

const ConfirmarLaudo = (props) => {
    const { html, callbackCancelar, open } = props;

    const { strings } = useContext(SttTranslateHook.I18nContext);
    
    return (
        <SttModal
            title={strings.resumoLaudo}
            open={open}
            outModalClose={callbackCancelar}
            iconClose={callbackCancelar}
            maxWidth="lg"
            fullWidth={true}
            children={
                <iframe 
                    title={strings.laudo}
                    srcDoc={html}
                    width="100%"
                    height={`${window.screen.height * 0.7}px`}
                    style={{ border: 'none' }}
                />
            }
            footer={
                <div>
                    <BotaoPublicar />
                    <SttButton variant="outlined" color="primary" onClick={callbackCancelar}>
                        {strings.cancelar}
                    </SttButton>
                </div>
            }
        />
    );
}

ConfirmarLaudo.propTypes = {
    html: PropTypes.string.isRequired,
    callbackConfirmar: PropTypes.func.isRequired, 
    callbackCancelar: PropTypes.func.isRequired, 
    open: PropTypes.bool.isRequired
};

export default ConfirmarLaudo;