import React from 'react';
import { temPerfilRBAC, temPermissaoRBAC } from '../../secutity/acl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, permissao, perfil, user }) => {
    if (permissao) {
        let temPermissao = false;
        if (Array.isArray(permissao)) {
            permissao.forEach(p => {
                if (temPermissaoRBAC(user, p)) {
                    temPermissao = true;
                }
            });
            return temPermissao ? <Component /> : <Redirect replace to='/nao-autorizado' />
        }
        return temPermissaoRBAC(user, permissao) ? <Component /> : <Redirect replace to='/nao-autorizado' />
    }
    let temPerfil = false;
    if (Array.isArray(perfil)) {
        perfil.forEach(p => {
            if (temPerfilRBAC(user, p)) {
                temPerfil = true;
            }
        });
        return temPerfil ? <Component /> : <Redirect replace to='/nao-autorizado' />
    }
    return temPerfilRBAC(user, perfil) ? <Component /> : <Redirect replace to='/nao-autorizado' />
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(ProtectedRoute);

