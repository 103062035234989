import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { SttTranslateHook } from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    circulo: {
        height: '20px',
        width: '20px',
        borderRadius: '50%',
        display: 'inline-block',
        border: 'none'
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center'
    },
    caption: {
        marginRight: theme.spacing(2)
    }

}));

const Legenda = ({...props}) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <div className={classes.flex}>
            <div className={[classes.flex, classes.caption].join(' ')}>
                <span className={classes.circulo} style={{backgroundColor: '#f4f3f3'}}/>
                <label style={{alignSelf: 'center', marginLeft: '5px'}}>{strings.direcionado}</label>
            </div>
            
            <div className={[classes.flex, classes.caption].join(' ')}>
                <span className={classes.circulo} style={{backgroundColor: '#f5e6f4'}}/>
                <label style={{alignSelf: 'center', marginLeft: '5px'}}>{strings.validado}</label>
            </div>
        </div>
    );

}

export default Legenda;