import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../request';
import { temPermissaoRBAC } from '../../secutity/acl';
import { 
    SttLoading, 
    SttGrid, 
    SttAlerta, 
    SttButton, 
    SttModal, 
    SttText, 
    SttAutocomplete, 
    SttInput, 
    SttTranslateHook 
} from '@stt-componentes/core';
import { PERMISSOES } from '../../common/Constants';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));

const Invalidacao = (props) => {
    const { user, invalidar, setInvalidar, idExame, idInstituicao, idRede, modalidade, callback } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    const classes = useStyles();
    const [alerta, setAlerta] = useState(false);
    const [tipoAlerta, setTipoAlerta] = useState('error');
    const [tituloAlerta, setTituloAlerta] = useState(strings.erro);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [motivos, setMotivos] = useState([]);
    const [motivo, setMotivoInvalidacao] = useState(null);
    const [observacao, setObservacao] = useState('');
    const [confirmarInvalidacao, setConfirmarInvalidacao] = useState(false);
    const [invalidando, setInvalidando] = useState(false);
    const [formValido, setFormValido] = useState(false);

    useEffect(() => {
        if (!motivos.length) {
            let params = { modalidade };
            if (idRede) {
                params.rede = idRede;
            }
            axios
                .get(`${EXAME_API_BASE_URL}/motivo-invalidacao`, { params, headers: getHeaders() })
                .then((response) => {
                    // Se não for médico, a seleção do motivo não é obrigatória pelo usuário
                    // É atribuído um motivo padrão
                    if (!temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME)) {
                        const motivosTecnico = response.data.filter(m => m.motivo_padrao_tecnico);
                        setMotivos(motivosTecnico);
                        if (motivosTecnico.length) {
                            handleChangeMotivo(null, motivosTecnico[0]);
                        }
                        return;
                    }
                    setMotivos(response.data);                    
                })
                .catch(err => console.log(err));
        }
    }, []);

    useEffect(() => {
        // Técnicos e administradores não podem laudar e só precisam da observação textual
        if (!temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME)) {
            if (observacao.trim()) {
                setFormValido(true);
            } else {
                setFormValido(false);
            }
        } else {
            // Laudadores precisam selecionar o motivo
            if (motivo) {
                setFormValido(true);
            } else {
                setFormValido(false);
            }
        }
    }, [observacao, motivo]);

    const fecharInvalidacao = () => {
        setMotivoInvalidacao(null);
        setInvalidar(false);
    }

    const handleChangeMotivo = (event, novoMotivo) => {
        setMotivoInvalidacao(novoMotivo);
    }

    const handleChangeObservacao = (event) => {
        setObservacao(event.target.value);
    }

    const invalidarExame = () => {
        setConfirmarInvalidacao(false);
        setInvalidando(true);

        axios
            .post(`${EXAME_API_BASE_URL}/invalidar`, { idExame, instituicao: idInstituicao, motivo: motivo.id, observacao: observacao.trim() }, { headers: getHeaders() })
            .then((resposta) => {
                setInvalidando(false);
                setTituloAlerta(strings.sucesso);
                setTipoAlerta('success');
                setMensagemAlerta(strings.exameInvalidado);
                setOpcoesAlerta([
                    { 
                        title: strings.ok, 
                        onClick: () => {
                            setAlerta(false);
                            if (callback) {
                                callback(true);
                            }
                            fecharInvalidacao();
                        } 
                    }
                ]);
                setAlerta(true);
            })
            .catch(err => {
                setInvalidando(false);
                console.log(err);
                setTituloAlerta(strings.erro);
                setTipoAlerta('error');
                setMensagemAlerta(strings.erroInvalidarExame);
                setOpcoesAlerta([{ title: strings.ok, onClick: () => setAlerta(false) }]);
                setAlerta(true);
            });
    }

    return (
        <>
            <SttModal
                title={strings.invalidar}
                open={invalidar}
                outModalClose={fecharInvalidacao}
                iconClose={fecharInvalidacao}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                {
                                    temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME) && 
                                    <SttText variant="body1">{strings.textoInvalidar}</SttText> ||
                                    <SttText variant="body1">{strings.textoInvalidarExameObs}</SttText>
                                }
                            </SttGrid>
                            {
                                temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME) && 
                                <SttGrid item xs={12}>
                                    <SttAutocomplete
                                        inputprops={{
                                            label: strings.motivo,
                                            required: true
                                        }}
                                        getOptionLabel={option => option.descricao}
                                        options={motivos}
                                        value={motivo}
                                        onChange={handleChangeMotivo}
                                    />
                                </SttGrid>
                            }
                            {
                                !temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME) && 
                                <SttGrid item xs={12}>
                                    <SttInput 
                                        value={observacao}
                                        label={strings.observacao}
                                        required
                                        onChange={handleChangeObservacao}
                                        multiline
                                        rows={3}
                                    />
                                </SttGrid>
                            }
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton 
                            variant="contained" 
                            color="primary" 
                            disabled={!formValido} 
                            onClick={() => setConfirmarInvalidacao(true)}
                        >
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharInvalidacao}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />

            <SttAlerta
                open={confirmarInvalidacao}
                title={strings.atencao}
                message={strings.confirmarInvalidarExame}
                type={'alert'}
                onClose={() => {
                    setConfirmarInvalidacao(false);
                }}
                options={
                    [
                        {
                            title: strings.sim,
                            onClick: invalidarExame
                        },
            
                        {
                            title: strings.nao,
                            onClick: () => {
                                setConfirmarInvalidacao(false);
                            }
                        }
                    ]
                }
            />
            <div className={classes.carregando}>
                <SttLoading
                    open={invalidando}
                    text={strings.invalidandoExame}
                />
            </div>
            <SttAlerta
                open={alerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={() => setAlerta(false)}
            />
        </>
    );
};

Invalidacao.propTypes = {
    user: PropTypes.object.isRequired,
    idExame: PropTypes.number.isRequired,
    invalidar: PropTypes.bool.isRequired, 
    setInvalidar: PropTypes.func.isRequired, 
    idRede: PropTypes.number,
    callback: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(Invalidacao);