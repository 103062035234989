import * as yup from 'yup';
import { 
    ID_EXAME,
    ID_LAUDO_EMISSAO,
    ID_PACIENTE,
    NOME_PACIENTE,
    DATA_NASCIMENTO_PACIENTE,
    SEXO_PACIENTE,
    CODIGO_MODALIDADE,
    SIGLA_MODALIDADE,
    DESCRICAO_MODALIDADE,
    NUMERO_REQUISICAO,
    TIMESTAMP_INICIO,
    PROFISSIONAL_DIRECIONADO,
    CONTEUDO_TEXTUAL
} from './fieldNames';
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';
import { FIELDS } from '@stt-componentes/decs/dist/lib/fieldNames';

const { DECS } = FIELDS;

export default () => {
    return yup.object().shape({
        [ID_EXAME]: yup.number(),
        [ID_LAUDO_EMISSAO]: yup.number(),
        [TIMESTAMP_INICIO]: yup.number(),
        [ID_PACIENTE]: yup.number(),
        [NOME_PACIENTE]: yup.string(),
        [DATA_NASCIMENTO_PACIENTE]: yup.date().nullable(),
        [SEXO_PACIENTE]: yup.string().nullable(),
        [CODIGO_MODALIDADE]: yup.string(),
        [SIGLA_MODALIDADE]: yup.string(),
        [DESCRICAO_MODALIDADE]: yup.string(),
        [NUMERO_REQUISICAO]: yup.string(),
        [CID_10]: yup.array().of(yup.object()).nullable(),
        [DECS]: yup.array().of(yup.object()).nullable(),
        [PROFISSIONAL_DIRECIONADO]: yup.object().nullable(),
        [CONTEUDO_TEXTUAL]: yup.object()
    });
}
