export const modeloPadrao = [
    {
        secao: 'Descrição do estudo',
        conteudo: ''
    },
    {
        secao: 'Achados',
        conteudo: ''
    },
    {
        secao: 'Conclusão',
        conteudo: ''
    }
];

export const modeloPersonalizado = (dados) => {
    const modeloPersonalizado = [];
    for (const secao in dados) {
        if (Array.isArray(dados[secao])) {
            continue;
        }
        if (Number.isInteger(dados[secao])) {
            continue;
        }
        modeloPersonalizado.push({
            secao: secao,
            conteudo: dados[secao]
        });
    }
    return modeloPersonalizado;
}
