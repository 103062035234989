import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import HistoryIcon from '@material-ui/icons/History';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PrintIcon from '@material-ui/icons/Print';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ExameImagemIcon from './imagens/exame-imagem.png';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Legenda from './legenda';
import DescriptionIcon from '@material-ui/icons/Description';
import Form from './form-pesquisa';
import { setAtualizarBusca as setAtualizarBuscaAction } from '../../reducers/actions/exame';
import { PERFIL, PERMISSOES, MODALIDADES_TELEDIAGNOSTICO, SIM } from '../../common/Constants';
import { temPerfilRBAC, temPermissaoRBAC } from '../../secutity/acl';
import Cookies from 'js-cookie';
import BlockIcon from '@material-ui/icons/Block';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import lodash from 'lodash';
import {
	SttTable,
	SttTableHead,
	SttTableBody,
	SttTableRow,
	SttTableCell,
	SttTablePagination,
	SttCircularProgress,
	SttMenu,
	SttMenuItem,
	SttListItemIcon,
	SttListItemText,
	SttGrid,
	SttLoading,
	SttAlerta,
	SttHidden,
	SttTableSortLabel,
	SttText,
	SttTranslateHook,
	SttButton,
	SttCheckbox,
} from '@stt-componentes/core';
import { Chip, IconButton } from '@material-ui/core';
import { SITUACAO, SITUACAO_LAUDO, PAGINACAO, LAUDO_EM_EMISSAO } from './constantes';
import { useMoment } from 'src/hooks';
import AbaHistoricoPaciente from './abaHistoricoPaciente';
import { ListaExames } from '@stt-utilitarios/core';
import WebIcon from '@material-ui/icons/Web';
import Invalidar from './invalidar';
import Utils from '../../utils';

const useStyles = makeStyles((theme) => ({
	tableWrapper: {
		marginTop: theme.spacing(3),
	},
	carregando: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%',
	},
	contentWrapper: {
		marginTop: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	conteineDirecionado: {
		display: 'flex',
		alignItems: 'end'
	}
}));

const ORDENACAO = {
	DIRECAO: {
		ASC: 'asc',
		DESC: 'desc',
	},
	CAMPOS: {
		DATA: 'data_exame',
		INSTITUICAO: 'instituicao',
		MUNICIPIO: 'municipio',
		TECNICO: 'funcionario_envio',
		REQUISICAO: 'requisicao',
		PACIENTE: 'paciente',
		DESCRICAOEXAME: 'descricao_exame',
		SITUACAO: 'situacao_laudo',
		IMAGEM: 'possui_imagem',
	},
};

const Pesquisa = (props) => {
	const { user, filtros, atualizarBusca, setAtualizarBusca, adicionarAba, imprimirFn, imprimirProtocoloFn, imprimirMultiplosProtocolosFn } = props;
	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();
	const moment = useMoment();
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const [exames, setExames] = useState([]);
	const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
	const [page, setPage] = useState(filtros?.page || global.gConfig.pagination.start);
	const [count, setCount] = useState(filtros?.count || global.gConfig.pagination.count);
	const [totalRegistros, setTotalRegistros] = useState(0);
	const [colspan, setColspan] = useState(9);
	const [menuOptions, setMenuOption] = useState([]);
	const [baixandoEstudo, setBaixandoEstudo] = useState(false);
	const [erro, setErro] = useState(false);
	const [order, setOrder] = useState(null);
	const [orderBy, setOrderBy] = useState(null);
	const [exameInvalidar, setExameInvalidar] = useState(null);
	// Seleção de exames
	const [examesSelecionados, setExamesSelecionados] = useState([]);

	const downloadEstudoDicom = (exame) => {
		setBaixandoEstudo(true);
		Utils.downloadFn(exame, () => setBaixandoEstudo(false));
	}

	const callbackInvalidacao = () => {
		setExameInvalidar(null);
		setAtualizarBusca(true);
	}

	useEffect(() => {
		if (temPerfilRBAC(user, [PERFIL.ADMINISTRADOR, PERFIL.ADMINISTRADOR_ESTADUAL])) {
			setColspan(11);
		} else if (temPerfilRBAC(user, [PERFIL.TECNICO, PERFIL.MEDICO_LAUDADOR, PERFIL.MEDICO_EXECUTOR])) {
			setColspan(10);
		}

		let menu = [];
		if (temPermissaoRBAC(user, [PERMISSOES.VISUALIZAR_EXAME, PERMISSOES.VISUALIZA_LAUDO_TEMP])) {
			menu.push({
				showFunction: (exame) => {
					return [
						SITUACAO_LAUDO.COM_LAUDO,
						SITUACAO_LAUDO.SEM_LAUDO,
						SITUACAO_LAUDO.LAUDO_TEMPORARIO,
						SITUACAO_LAUDO.LAUDO_EMISSAO,
						SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
					].includes(exame.situacao_laudo);
				},
				icon: VisibilityIcon,
				title: strings.ver,
				createOnClick: (exame) => {
					history.push('/exames/exame', {
						exame: exame.id,
						instituicao: { id: exame.id_instituicao, nome: exame.instituicao },
					});
				},
			});
		}

		if (temPermissaoRBAC(user, PERMISSOES.DOWNLOAD_EXAME)) {
			menu.push({
				showFunction: (exame) => {
					return (
						[
							SITUACAO_LAUDO.COM_LAUDO,
							SITUACAO_LAUDO.SEM_LAUDO,
							SITUACAO_LAUDO.LAUDO_TEMPORARIO,
							SITUACAO_LAUDO.LAUDO_EMISSAO,
							SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
						].includes(exame.situacao_laudo) &&
						!MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade) &&
						exame.possui_imagem === SIM
					);
				},
				icon: CloudDownloadIcon,
				title: strings.download,
				createOnClick: downloadEstudoDicom,
			});
		}

		if (temPerfilRBAC(user, [PERFIL.MEDICO_EXECUTOR, PERFIL.MEDICO_RESIDENTE])) {
			menu.push({
				showFunction: (exame) => {
					const valido = exame.situacao === SITUACAO.VALIDO;

					const situacaoLaudo = [
						SITUACAO_LAUDO.SEM_LAUDO,
						SITUACAO_LAUDO.LAUDO_TEMPORARIO,
						SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
					].includes(exame.situacao_laudo);

					const modalidade = !MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade);

					const laudador = exame.id_funcionario_laudador === user.idFuncionario;

					const emitindoLaudo = exame.laudo_em_emissao === LAUDO_EM_EMISSAO.SIM &&
						exame.id_funcionario_emissao === user.idFuncionario;

					return valido && modalidade && (situacaoLaudo  || laudador || emitindoLaudo);
				},
				icon: DescriptionIcon,
				title: strings.laudo,
				createOnClick: (exame) => {
					history.push('/exames/laudo', { exame: exame.id });
				},
			});
		}

		if (temPermissaoRBAC(user, PERMISSOES.HISTORICO_PACIENTE)) {
			menu.push({
				showFunction: (exame) => {
					return [
						SITUACAO_LAUDO.COM_LAUDO,
						SITUACAO_LAUDO.SEM_LAUDO,
						SITUACAO_LAUDO.LAUDO_TEMPORARIO,
						SITUACAO_LAUDO.LAUDO_EMISSAO,
						SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
					].includes(exame.situacao_laudo);
				},
				icon: HistoryIcon,
				title: strings.historicoPaciente,
				createOnClick: (exame) => {
					const abaHistorico = {
						titulo: `${exame.paciente}`,
						conteudo: AbaHistoricoPaciente,
						key: `${exame.id_paciente}_${exame.origem_paciente}`,
						idAba: `${exame.id_paciente}_${exame.origem_paciente}`,
						idPaciente: exame.id_paciente,
						origemPaciente: exame.origem_paciente,
					};
					adicionarAba(abaHistorico);
				},
			});
		}



		menu.push({
			showFunction: (exame) => {
				return (
					exame.situacao === SITUACAO.VALIDO &&
					[
						SITUACAO_LAUDO.COM_LAUDO,
						SITUACAO_LAUDO.SEM_LAUDO,
						SITUACAO_LAUDO.LAUDO_TEMPORARIO,
						SITUACAO_LAUDO.LAUDO_EMISSAO,
						SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
					].includes(exame.situacao_laudo)
				);
			},
			apenasExameValido: true,
			aplicaA: [
				SITUACAO_LAUDO.COM_LAUDO,
				SITUACAO_LAUDO.SEM_LAUDO,
				SITUACAO_LAUDO.LAUDO_TEMPORARIO,
				SITUACAO_LAUDO.LAUDO_EMISSAO,
				SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
			],
			icon: PrintIcon,
			title: strings.imprimir,
			createOnClick: (exame) => imprimirFn(exame),
		});

		menu.push({
			showFunction: (exame) => {
				return exame.situacao === SITUACAO.VALIDO &&
					[
						SITUACAO_LAUDO.COM_LAUDO,
						SITUACAO_LAUDO.SEM_LAUDO,
						SITUACAO_LAUDO.LAUDO_TEMPORARIO,
						SITUACAO_LAUDO.LAUDO_EMISSAO,
						SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
						SITUACAO_LAUDO.DIGITADO,
						SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
						SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
					].includes(exame.situacao_laudo);

			},
			apenasExameValido: true,
			aplicaA: [
				SITUACAO_LAUDO.COM_LAUDO,
				SITUACAO_LAUDO.SEM_LAUDO,
				SITUACAO_LAUDO.LAUDO_TEMPORARIO,
				SITUACAO_LAUDO.LAUDO_EMISSAO,
				SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
				SITUACAO_LAUDO.DIGITADO,
				SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
				SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
			],
			icon: WebIcon,
			title: strings.protocolo,
			createOnClick: (exame) => {
				imprimirProtocoloFn(exame);
			},
		});

		menu.push({
			showFunction: (exame) => {
				return !MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade) &&
					temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME) &&
					exame.situacao === SITUACAO.VALIDO &&
					[
						SITUACAO_LAUDO.COM_LAUDO,
						SITUACAO_LAUDO.SEM_LAUDO,
						SITUACAO_LAUDO.LAUDO_TEMPORARIO,
						SITUACAO_LAUDO.LAUDO_EMISSAO,
						SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
						SITUACAO_LAUDO.DIGITADO,
						SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
						SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
					].includes(exame.situacao_laudo);
			},
			apenasExameValido: true,
			aplicaA: [
				SITUACAO_LAUDO.COM_LAUDO,
				SITUACAO_LAUDO.SEM_LAUDO,
				SITUACAO_LAUDO.LAUDO_TEMPORARIO,
				SITUACAO_LAUDO.LAUDO_EMISSAO,
				SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
				SITUACAO_LAUDO.DIGITADO,
				SITUACAO_LAUDO.COM_LAUDO_APLICATIVO,
				SITUACAO_LAUDO.AGUARDANDO_TRANSCRICAO
			],
			icon: BlockIcon,
			title: strings.invalidar,
			createOnClick: (exame) => {
				setExameInvalidar(exame);
			},
		});

		setMenuOption(menu);
	}, []);

	useEffect(() => {
		if (location.state?.from === 'visualizar') {
			setAtualizarBusca(true);
		}
	}, [location.pathname]);

	const resetPageCount = () => {
		setPage(global.gConfig.pagination.start);
		setCount(
			parseInt(
				Cookies.get(PAGINACAO.REGISTROS_PAGINA) ||
				global.gConfig.pagination.count
			)
		);
		setExamesSelecionados([]);
		setOrder(null);
		setOrderBy(null);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		setAtualizarBusca(true);
	};

	const handleClickSort = (campo) => {
		const isAsc = orderBy === campo && order === ORDENACAO.DIRECAO.ASC;
		const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
		setOrder(newOrder);
		setOrderBy(campo);
		setAtualizarBusca(true);
	};

	const handleChangeRowsPerPage = (event) => {
		const count = event.target.value;
		Cookies.set(PAGINACAO.REGISTROS_PAGINA, count);
		setCount(count);
		setPage(global.gConfig.pagination.start);
		setAtualizarBusca(true);
	};

	const callbackBusca = (dados) => {
		if (dados) {
			setTotalRegistros(parseInt(dados.totalRegistros));
			setExames(dados.itens);
		} else {
			setTotalRegistros(0);
			setExames([]);
		}
		setAtualizarBusca(false);
	};

	const pintarLinhaGrid = (exame) => {
		const situacoesLaudo = [
			SITUACAO_LAUDO.SEM_LAUDO,
			SITUACAO_LAUDO.LAUDO_TEMPORARIO,
			SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO,
		];
		if (situacoesLaudo.includes(exame.situacao_laudo)) {
			if (exame.id_funcionario_direcionado) {
				return '#f4f3f3';
			} else {
				if (
					exame.possui_anexo === SIM ||
					exame.possui_indicacao_clinica === SIM
				) {
					return '#f5e6f4';
				}
			}
		}
	};

	const selecionarTodosExames = (selecionar) => {
		if (!exames.length) {
			return;
		}
		if (selecionar) {
			// adiciona os últimos selecionados (página atual) ao array de selecionados anteriormente (páginas anteriores)
			const selecionados = [...examesSelecionados, ...exames];
			// remove duplicidades e atualiza o estado do componente
			const filtrados = selecionados.filter((value, index, self) =>
				index === self.findIndex((t) => (t.id === value.id))
			)
			setExamesSelecionados([...filtrados]);
		} else {
			// mantém apenas os exames selecionados em páginas anteriores
			const selecionados = examesSelecionados.filter(e => !exames.some(ep => ep.id === e.id));
			// atualiza o estado do componente
			setExamesSelecionados([...selecionados]);
		}
	}

	const imprimirMultiplosProtocolos = () => {
		if (examesSelecionados.length === 0) {
			return;
		}
		imprimirMultiplosProtocolosFn(examesSelecionados);
	}

	return (
		<>
			<Form
				callbackBusca={callbackBusca}
				callbackAndamento={setBuscaEmAndamento}
				setExamesSelecionados={setExamesSelecionados}
				page={page}
				count={count}
				buscar={atualizarBusca}
				resetPageCount={resetPageCount}
				sort={orderBy}
				sortOrder={order}
			/>
			<SttGrid container spacing={3}>
				<SttGrid item xs={12}>
					<div className={classes.tableWrapper}>
						<SttTable>
							<caption colSpan={colspan}>
								<Legenda />
								<div className={classes.contentWrapper}>
									{
										examesSelecionados.length === 1 && `${examesSelecionados.length} ${strings.exameSelecionado}`
									}
									{
										examesSelecionados.length > 1 && `${examesSelecionados.length} ${strings.examesSelecionados}`
									}
									{
										examesSelecionados.length > 0 &&
										<SttButton
											type="button"
											variant="contained"
											color="secondary"

											onClick={imprimirMultiplosProtocolos}
										>
											{strings.gerarProtocolos}
										</SttButton>
									}
								</div>
							</caption>
							<SttTableHead>
								<SttTableRow>
									<SttHidden smDown>
										<SttTableCell
											width='0.5%'
										>
											<SttCheckbox
												color="primary"
												checked={exames.length > 0 && (examesSelecionados.filter(e => (exames.map(ex => ex.id)).includes(e.id))).length === exames.length}
												onChange={(evt, value) => selecionarTodosExames(value)}
											/>
										</SttTableCell>
										<SttTableCell
											width='2%'
											sortDirection={
												orderBy === ORDENACAO.CAMPOS.DATA ? order : false
											}
										>
											<SttTableSortLabel
												active={orderBy === ORDENACAO.CAMPOS.DATA}
												direction={
													orderBy === ORDENACAO.CAMPOS.DATA
														? order
														: ORDENACAO.DIRECAO.ASC
												}
												onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA)}
											>
												{strings.data}
											</SttTableSortLabel>
										</SttTableCell>

										<SttTableCell
											width='16%'
											sortDirection={
												orderBy === ORDENACAO.CAMPOS.INSTITUICAO ? order : false
											}
										>
											<SttTableSortLabel
												active={orderBy === ORDENACAO.CAMPOS.INSTITUICAO}
												direction={
													orderBy === ORDENACAO.CAMPOS.INSTITUICAO
														? order
														: ORDENACAO.DIRECAO.ASC
												}
												onClick={() =>
													handleClickSort(ORDENACAO.CAMPOS.INSTITUICAO)
												}
											>
												{strings.instituicao}
											</SttTableSortLabel>
										</SttTableCell>

										{(temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
											temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) && (
												<>
													<SttTableCell
														width='12%'
														sortDirection={
															orderBy === ORDENACAO.CAMPOS.MUNICIPIO
																? order
																: false
														}
													>
														<SttTableSortLabel
															active={orderBy === ORDENACAO.CAMPOS.MUNICIPIO}
															direction={
																orderBy === ORDENACAO.CAMPOS.MUNICIPIO
																	? order
																	: ORDENACAO.DIRECAO.ASC
															}
															onClick={() =>
																handleClickSort(ORDENACAO.CAMPOS.MUNICIPIO)
															}
														>
															{`${strings.municipio}/${strings.uf}`}
														</SttTableSortLabel>
													</SttTableCell>
												</>
											)}
										{(temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
											temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) ||
											temPerfilRBAC(user, PERFIL.ADMINISTRADOR_TELEMEDICINA) ||
											temPerfilRBAC(user, PERFIL.ADMINISTRADOR_LOCAL) ||
											temPerfilRBAC(user, PERFIL.TECNICO) ||
											temPerfilRBAC(user, PERFIL.MEDICO_LAUDADOR)) && (
												<SttTableCell
													width='10%'
													sortDirection={
														orderBy === ORDENACAO.CAMPOS.TECNICO ? order : false
													}
												>
													<SttTableSortLabel
														active={orderBy === ORDENACAO.CAMPOS.TECNICO}
														direction={
															orderBy === ORDENACAO.CAMPOS.TECNICO
																? order
																: ORDENACAO.DIRECAO.ASC
														}
														onClick={() =>
															handleClickSort(ORDENACAO.CAMPOS.TECNICO)
														}
													>
														{strings.responsavel}
													</SttTableSortLabel>
												</SttTableCell>
											)}
										<SttTableCell
											width='5%'
											sortDirection={
												orderBy === ORDENACAO.CAMPOS.REQUISICAO ? order : false
											}
										>
											<SttTableSortLabel
												active={orderBy === ORDENACAO.CAMPOS.REQUISICAO}
												direction={
													orderBy === ORDENACAO.CAMPOS.REQUISICAO
														? order
														: ORDENACAO.DIRECAO.ASC
												}
												onClick={() =>
													handleClickSort(ORDENACAO.CAMPOS.REQUISICAO)
												}
											>
												{strings.requisicao}
											</SttTableSortLabel>
										</SttTableCell>
										<SttTableCell
											width='10%'
											sortDirection={
												orderBy === ORDENACAO.CAMPOS.PACIENTE ? order : false
											}
										>
											<SttTableSortLabel
												active={orderBy === ORDENACAO.CAMPOS.PACIENTE}
												direction={
													orderBy === ORDENACAO.CAMPOS.PACIENTE
														? order
														: ORDENACAO.DIRECAO.ASC
												}
												onClick={() =>
													handleClickSort(ORDENACAO.CAMPOS.PACIENTE)
												}
											>
												{strings.paciente}
											</SttTableSortLabel>
										</SttTableCell>
										<SttTableCell
											width='10%'
											sortDirection={
												orderBy === ORDENACAO.CAMPOS.DESCRICAOEXAME
													? order
													: false
											}
										>
											<SttTableSortLabel
												active={orderBy === ORDENACAO.CAMPOS.DESCRICAOEXAME}
												direction={
													orderBy === ORDENACAO.CAMPOS.DESCRICAOEXAME
														? order
														: ORDENACAO.DIRECAO.ASC
												}
												onClick={() =>
													handleClickSort(ORDENACAO.CAMPOS.DESCRICAOEXAME)
												}
											>
												{strings.descricao}
											</SttTableSortLabel>
										</SttTableCell>

										<SttTableCell
											width='5%'
											sortDirection={
												orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : false
											}
										>
											<SttTableSortLabel
												active={orderBy === ORDENACAO.CAMPOS.SITUACAO}
												direction={
													orderBy === ORDENACAO.CAMPOS.SITUACAO
														? order
														: ORDENACAO.DIRECAO.ASC
												}
												onClick={() =>
													handleClickSort(ORDENACAO.CAMPOS.SITUACAO)
												}
											>
												{strings.situacao}
											</SttTableSortLabel>
										</SttTableCell>

										<SttTableCell
											align='center'
											width='3%'
											sortDirection={
												orderBy === ORDENACAO.CAMPOS.IMAGEM ? order : false
											}
										>
											<SttTableSortLabel
												active={orderBy === ORDENACAO.CAMPOS.IMAGEM}
												direction={
													orderBy === ORDENACAO.CAMPOS.IMAGEM
														? order
														: ORDENACAO.DIRECAO.ASC
												}
												onClick={() => handleClickSort(ORDENACAO.CAMPOS.IMAGEM)}
											>
												{strings.imagem}
											</SttTableSortLabel>
										</SttTableCell>

										<SttTableCell align='center' width='5%'>
											{strings.opcoes}
										</SttTableCell>
									</SttHidden>
									<SttHidden mdUp>
										<SttTableCell width="5%">
											<SttCheckbox
												color="primary"
												checked={exames.length > 0 && (examesSelecionados.filter(e => (exames.map(ex => ex.id)).includes(e.id))).length === exames.length}
												onChange={(evt, value) => selecionarTodosExames(value)}
											/>
										</SttTableCell>
										<SttTableCell width="90%">
											{strings.dadosExame}
										</SttTableCell>
										<SttTableCell width="5%" align='center'>{strings.opcoes}</SttTableCell>
									</SttHidden>
								</SttTableRow>
							</SttTableHead>
							<SttTableBody>
								{buscaEmAndamento ? (
									<SttTableRow key={-1}>
										<SttTableCell colSpan={colspan} align='center'>
											<SttCircularProgress />
										</SttTableCell>
									</SttTableRow>
								) : exames.length === 0 ? (
									<SttTableRow key={-1}>
										<SttTableCell colSpan={colspan} align='center'>
											{strings.nenhumRegistroEncontrado}
										</SttTableCell>
									</SttTableRow>
								) : (
									exames.map((row, index) => (
										<SttTableRow
											key={index}
											style={{ backgroundColor: pintarLinhaGrid(row) }}
										>
											<SttTableCell>
												<SttCheckbox
													color="primary"
													checked={examesSelecionados.some(es => es.id === row.id)}
													onChange={(evt, value) => {
														if (value) {
															setExamesSelecionados(es => [...es, row]);
														} else {
															setExamesSelecionados(es => es.filter(e => e.id !== row.id));
														}
													}}
												/>
											</SttTableCell>
											<SttHidden smDown>
												<SttTableCell align="center">{`${moment(row.data_exame).format('DD/MM/YYYY HH:mm')}`}</SttTableCell>
												<SttTableCell>{lodash.startCase(lodash.lowerCase(row.instituicao))}</SttTableCell>
												{(temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
													temPerfilRBAC(
														user,
														PERFIL.ADMINISTRADOR_ESTADUAL
													)) && (
														<SttTableCell>
															{lodash.startCase(lodash.lowerCase(row.municipio))} / {row.uf}
														</SttTableCell>
													)}
												{(temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
													temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) ||
													temPerfilRBAC(
														user,
														PERFIL.ADMINISTRADOR_TELEMEDICINA
													) ||
													temPerfilRBAC(user, PERFIL.ADMINISTRADOR_LOCAL) ||
													temPerfilRBAC(user, PERFIL.TECNICO) ||
													temPerfilRBAC(user, PERFIL.MEDICO_LAUDADOR)) && (
														<SttTableCell>
															{lodash.startCase(lodash.lowerCase(row.funcionario_envio))}
															{
																row.situacao_laudo !== SITUACAO_LAUDO.COM_LAUDO &&
																row.funcionario_direcionado &&
																<div className={classes.conteineDirecionado}>
																	<SubdirectoryArrowRightIcon />
																	{lodash.startCase(lodash.lowerCase(row.funcionario_direcionado))}
																</div>
															}
														</SttTableCell>
													)}
												<SttTableCell style={{ wordBreak: 'break-word' }}>{row.requisicao}</SttTableCell>
												<SttTableCell>{lodash.startCase(lodash.lowerCase(row.paciente))}</SttTableCell>
												<SttTableCell>{lodash.startCase(lodash.lowerCase(row.descricao_exame))}</SttTableCell>
												<SttTableCell>
													<Chip
														style={{
															backgroundColor: ListaExames.getCorLegenda(row),
														}}
														label={ListaExames.getTextoLegenda(row, strings)}
														size='small'
													/>
												</SttTableCell>
												<SttTableCell align='center'>
													{row.possui_imagem === SIM && (
														<img src={ExameImagemIcon} width={36} />
													)}
												</SttTableCell>
												<SttTableCell align='center'>
													{menuOptions.length > 0 && (
														<PopupState variant='popover'>
															{(popupState) => (
																<>
																	<IconButton
																		aria-haspopup='true'
																		{...bindTrigger(popupState)}
																	>
																		<MoreVertIcon />
																	</IconButton>
																	<SttMenu {...bindMenu(popupState)}>
																		{menuOptions.map((item, idx) => {
																			if (item.showFunction(row)) {
																				return (
																					<SttMenuItem
																						key={idx}
																						onClick={() => {
																							popupState.close();
																							item.createOnClick(row);
																						}}
																					>
																						<SttListItemIcon>
																							<item.icon />
																						</SttListItemIcon>
																						<SttListItemText
																							primary={item.title}
																						/>
																					</SttMenuItem>
																				);
																			}
																		})}
																	</SttMenu>
																</>
															)}
														</PopupState>
													)}
												</SttTableCell>
											</SttHidden>
											<SttHidden mdUp>
												<SttTableCell>
													<SttText size='small'>
														<b>{strings.data}: </b>{`${moment(row.data_exame).format('DD/MM/YYYY HH:mm')}`}
													</SttText>
													{(temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
														temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) && (
															<>
																<SttText size='small'>
																	<b>{`${strings.municipio}/${strings.uf}`}: </b>
																	{lodash.startCase(lodash.lowerCase(row.municipio))} / {row.uf}
																</SttText>
															</>
														)}
													<SttText size='small'>
														<b>{strings.instituicao}: </b> {lodash.startCase(lodash.lowerCase(row.instituicao))}
													</SttText>
													{row.funcionario_envio && (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
														temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) ||
														temPerfilRBAC(user, PERFIL.ADMINISTRADOR_TELEMEDICINA) ||
														temPerfilRBAC(user, PERFIL.ADMINISTRADOR_LOCAL) ||
														temPerfilRBAC(user, PERFIL.TECNICO) ||
														temPerfilRBAC(user, PERFIL.MEDICO_LAUDADOR)) && (
															<SttText size='small'>
																<b>{strings.responsavel}: </b>
																{lodash.startCase(lodash.lowerCase(row.funcionario_envio))}
																{
																	row.situacao_laudo !== SITUACAO_LAUDO.COM_LAUDO &&
																	row.funcionario_direcionado &&
																	<div className={classes.conteineDirecionado}>
																		<SubdirectoryArrowRightIcon />
																		{lodash.startCase(lodash.lowerCase(row.funcionario_direcionado))}
																	</div>
																}
															</SttText>
														)}
													<SttText size='small'>
														<b>{strings.requisicao}: </b> {row.requisicao}
													</SttText>
													<SttText size='small'>
														<b>{strings.paciente}: </b> {lodash.startCase(lodash.lowerCase(row.paciente))}
													</SttText>
													<SttText size='small'>
														<b>{strings.descricao}: </b> {lodash.startCase(lodash.lowerCase(row.descricao_exame))}
													</SttText>
													<SttText size='small'>
														<b>{strings.situacao}:  </b>
														<Chip
															style={{
																backgroundColor: ListaExames.getCorLegenda(row),
															}}
															label={ListaExames.getTextoLegenda(row, strings)}
															size='small'
														/>
													</SttText>
													<SttText size='small'>
														{row.possui_imagem === SIM && (
															<b
																style={{
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																{strings.imagem}:
																<img
																	src={ExameImagemIcon}
																	width={25}
																	style={{ marginLeft: '5px' }}
																/>
															</b>
														)}
													</SttText>
												</SttTableCell>
												<SttTableCell align='center'>
													{menuOptions.length > 0 && (
														<PopupState variant='popover'>
															{(popupState) => (
																<>
																	<IconButton
																		aria-haspopup='true'
																		{...bindTrigger(popupState)}
																	>
																		<MoreVertIcon />
																	</IconButton>
																	<SttMenu {...bindMenu(popupState)}>
																		{menuOptions.map((item, idx) => {
																			if (item.showFunction(row)) {
																				return (
																					<SttMenuItem
																						key={idx}
																						onClick={() => {
																							popupState.close();
																							item.createOnClick(row);
																						}}
																					>
																						<SttListItemIcon>
																							<item.icon />
																						</SttListItemIcon>
																						<SttListItemText
																							primary={item.title}
																						/>
																					</SttMenuItem>
																				);
																			}
																		})}
																	</SttMenu>
																</>
															)}
														</PopupState>
													)}
												</SttTableCell>
											</SttHidden>
										</SttTableRow>
									))
								)}
							</SttTableBody>
						</SttTable>
					</div>
				</SttGrid>
			</SttGrid>
			{exames.length > 0 ? (
				<SttTablePagination
					rowsPerPageOptions={[10, 20, 40, 100]}
					component='div'
					count={totalRegistros}
					rowsPerPage={count}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={strings.linhasPorPagina}
					labelDisplayedRows={(params) =>
						`${params.from}-${params.to} ${strings.de} ${params.count}`
					}
				/>
			) : null}
			<div className={classes.carregando}>
				<SttLoading open={baixandoEstudo} text={strings.baixandoExame} />
			</div>

			<SttAlerta
				open={erro}
				title={strings.erro}
				message={strings.erroDownloaEstudoDicom}
				type='error'
				options={[{ title: strings.ok, onClick: () => setErro(false) }]}
				onClose={() => setErro(false)}
			/>

			{
				exameInvalidar &&
				<Invalidar
					invalidar={exameInvalidar}
					setInvalidar={setExameInvalidar}
					idExame={exameInvalidar.id}
					callback={callbackInvalidacao}
					modalidade={exameInvalidar.sigla_modalidade}
				/>
			}
		</>
	);
};

Pesquisa.propTypes = {
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.index.user,
		atualizarBusca: state.exame.atualizarBusca,
		filtros: state.exame.filtros,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAtualizarBusca: (atualizar) =>
			dispatch(setAtualizarBuscaAction(atualizar)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Pesquisa);
