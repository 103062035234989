import React, { useContext } from 'react';
import { Field } from 'formik';
import { SttFormControl, SttFormGroup, SttFormControlLabel, SttCheckbox, SttTranslateHook } from '@stt-componentes/core';
import { SOMENTE_COM_IMAGENS } from '../fieldNames';

const ComImagem = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    
    return (
        <Field name={SOMENTE_COM_IMAGENS}>
            {({
                field: { checked, name, ...other},
                form
                
            }) => (
                <SttFormControl variant="outlined">
                    <SttFormGroup row>
                        <SttFormControlLabel
                            control={
                                <SttCheckbox
                                    {...other}
                                    namne={name}
                                    color="primary"
                                    checked={checked || form.values[SOMENTE_COM_IMAGENS]}
                                    onChange={e => form.setFieldValue(name, e.target.checked)}
                                    
                                />
                            }
                            label={strings.comImagem}
                        />
                        
                    </SttFormGroup>
                </SttFormControl>
                
            )}
        </Field>
    );
};

export default ComImagem;