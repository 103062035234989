import React, { useContext } from 'react';
import {  Field } from 'formik';
import { SITUACAO_LAUDO } from '../../constantes';
import { SttFormControl, SttFormGroup, SttFormControlLabel, SttCheckbox, SttTranslateHook } from '@stt-componentes/core';
import { SITUACAO_EXAME_LAUDO, SEM_LAUDO_72H } from '../fieldNames';

const SituacaoLaudo = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    return (
        <>
            <Field name={SITUACAO_EXAME_LAUDO}>
                {({
                    field,
                    form
                }) => (
                    <SttFormControl variant="outlined">
                        <SttFormGroup row>
                            <SttFormControlLabel
                                control={
                                    <SttCheckbox
                                        {...field}
                                        value={SITUACAO_LAUDO.COM_LAUDO}
                                        color="primary"
                                        checked={!form.values[SEM_LAUDO_72H] && form.values[SITUACAO_EXAME_LAUDO].includes(SITUACAO_LAUDO.COM_LAUDO)}
                                        disabled={form.values[SEM_LAUDO_72H]}
                                    />
                                }
                                label={strings.comLaudo}
                            />
                            <SttFormControlLabel
                                control={
                                    <SttCheckbox
                                        {...field}
                                        value={SITUACAO_LAUDO.SEM_LAUDO}
                                        color="primary"
                                        checked={form.values[SITUACAO_EXAME_LAUDO].includes(SITUACAO_LAUDO.SEM_LAUDO)}
                                    />
                                }
                                label={strings.semLaudo}
                            />
                            <SttFormControlLabel
                                control={
                                    <SttCheckbox
                                        {...field}
                                        value={SITUACAO_LAUDO.LAUDO_EMISSAO}
                                        color="primary"
                                        checked={!form.values[SEM_LAUDO_72H] && form.values[SITUACAO_EXAME_LAUDO].includes(SITUACAO_LAUDO.LAUDO_EMISSAO)}
                                        disabled={form.values[SEM_LAUDO_72H]}
                                    />
                                }
                                label={strings.laudoEmissao}
                            />
                            <SttFormControlLabel
                                control={
                                    <SttCheckbox
                                        {...field}
                                        value={SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO}
                                        color="primary"
                                        checked={!form.values[SEM_LAUDO_72H] && form.values[SITUACAO_EXAME_LAUDO].includes(SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO)}
                                        disabled={form.values[SEM_LAUDO_72H]}
                                    />
                                }
                                label={strings.laudoAguardandoComplemento}
                            />
                        </SttFormGroup>
                    </SttFormControl>
                )}
            </Field>
            <Field name={SEM_LAUDO_72H}>
                {({
                    field,
                    form
                }) => (
                    <SttFormControl variant="outlined">
                        <SttFormGroup row>
                            <SttFormControlLabel
                                control={
                                    <SttCheckbox
                                        {...field}
                                        value={true}
                                        color="primary"
                                        checked={form.values[SEM_LAUDO_72H]}
                                        onChange={event => {
                                            if (event.target.checked) {
                                                form.setFieldValue(SEM_LAUDO_72H, true);
                                                form.setFieldValue(SITUACAO_EXAME_LAUDO, [SITUACAO_LAUDO.SEM_LAUDO]);
                                            } else {
                                                form.setFieldValue(SEM_LAUDO_72H, false);
                                            }
                                        }}
                                    />
                                }
                                label={strings.semLaudo72h}
                            />
                        </SttFormGroup>
                    </SttFormControl>
                )}
            </Field>
        </>
    );
};
export default SituacaoLaudo;