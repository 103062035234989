export const ID_EXAME = 'idExame';
export const ID_LAUDO_EMISSAO = 'idLaudoEmissao';
export const TIMESTAMP_INICIO = 'timestampInicio';
export const ID_PACIENTE = 'idPaciente';
export const NOME_PACIENTE = 'nomePaciente';
export const DATA_NASCIMENTO_PACIENTE = 'dataNascimentoPaciente';
export const SEXO_PACIENTE = 'sexoPaciente';
export const CODIGO_MODALIDADE = 'codigoModalidade';
export const SIGLA_MODALIDADE = 'siglaModalidade';
export const DESCRICAO_MODALIDADE = 'descricaoModalidade';
export const NUMERO_REQUISICAO = 'numeroRequisicao';
export const PROFISSIONAL_DIRECIONADO = 'profissionalDirecionado';
export const MODELO_LAUDO_FUNCIONARIO = 'modeloLaudoFuncionario';
export const MODELO_LAUDO_INSTITUICAO = 'modeloLaudoInstituicao';
export const CONTEUDO_TEXTUAL = 'conteudoTextual';
export const DESCRICAO = 'descrição';
export const ACHADOS = 'achados';
export const CONCLUSAO = 'conclusão';
