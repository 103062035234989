import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Field } from 'formik';
import { ORIGEM } from '../../constantes';
import { SttFormControl, SttFormGroup, SttFormControlLabel, SttCheckbox, SttTranslateHook } from '@stt-componentes/core';
import { ORIGEM_EXAME } from '../fieldNames';
import { temPerfilRBAC } from '../../../../secutity/acl';
import { PERFIL } from 'src/common/Constants';

const Origem = ({ user }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    
    return (
        <Field name={ORIGEM_EXAME}>
            {({
                field,
                form
            }) => (
                <SttFormControl variant="outlined">
                    <SttFormGroup row>
                        <SttFormControlLabel
                            control={
                                <SttCheckbox
                                    {...field}
                                    value={ORIGEM.AGENDADOS}
                                    color="primary"
                                    checked={form.values[ORIGEM_EXAME].includes(ORIGEM.AGENDADOS)}
                                />
                            }
                            label={strings.agendados}
                        />
                        <SttFormControlLabel
                            control={
                                <SttCheckbox
                                    {...field}
                                    value={ORIGEM.PACS}
                                    color="primary"
                                    checked={form.values[ORIGEM_EXAME].includes(ORIGEM.PACS)}
                                />
                            }
                            label={strings.pacs}
                        />
                        {
                            !temPerfilRBAC(user, PERFIL.MEDICO_EXECUTOR) &&
                            <SttFormControlLabel
                                control={
                                    <SttCheckbox
                                        {...field}
                                        value={ORIGEM.TELEDIAGNOSTICO}
                                        color="primary"
                                        checked={form.values[ORIGEM_EXAME].includes(ORIGEM.TELEDIAGNOSTICO)}
                                    />
                                }
                                label={strings.telediagnostico}
                            />
                        }
                    </SttFormGroup>
                </SttFormControl>
            )}
        </Field>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filtros: state.exame.filtros
    };
};

export default connect(mapStateToProps)(Origem);