export const UF = 'uf';
export const MUNICIPIO = 'municipio';
export const INSTITUICAO = 'instituicao';
export const REDE = 'rede';
export const FUNCIONARIO_ENVIO = 'funcionario-envio';
export const REQUISICAO = 'requisicao';
export const NOME_PACIENTE = 'paciente';
export const CPF_PACIENTE = 'cpf-paciente';
export const CNS_PACIENTE = 'cns-paciente';
export const DATA_EXAME_DE = 'data-exame-de';
export const DATA_EXAME_ATE = 'data-exame-ate';
export const DATA_LAUDO_DE = 'data-laudo-de';
export const DATA_LAUDO_ATE = 'data-laudo-ate';
export const SITUACAO_EXAME_LAUDO = 'situacao-laudo';
export const SEM_LAUDO_72H = 'atrasado';
export const ULTRAPASSOU_TEMPO_PREVISTO = 'ultrapassou-tempo-previsto';
export const SOMENTE_COM_IMAGENS = 'imagens';
export const SITUACAO_EXAME = 'situacao';
export const ORIGEM_EXAME = 'origem';
export const PROTOCOLO = 'protocolo';
export const MODALIDADE = 'modalidade';
export const CATEGORIA = 'categoria';
export const NUMERO_EXAME = 'numero-exame';
export const DESCRICAO_EXAME = 'descricao-exame';
export const TIPO_BUSCA_DESCRICAO = 'tipo-busca-descricao';
export const EXAMES_DIRECIONADOS = 'direcionados';
export const EXAMES_VALIDADOS = 'exame-validado';
export const EXAMES_PRIORIZADOS = 'exame-priorizado';
export const LAUDADO_POR_MIM = 'responsavel-laudo';
