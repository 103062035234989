import TYPES from './types/assinatura-digital';

const INITIAL_STATE = {
    config: null,
    escopo: null,
    tokenTimestamp: null
};

const assinaturaDigital = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case TYPES.SET_CONFIG:            
            return {
                ...state,
                config: payload.config
            }
        case TYPES.SET_SCOPE:
            return {
                ...state,
                escopo: payload.scope
            };

        case TYPES.CLEAR_SCOPE:
            return {
                ...state,
                escopo: null
            };

        case TYPES.SET_TOKEN_TIMESTAMP:
            return {
                ...state,
                tokenTimestamp: payload.timestamp
            };

        case TYPES.CLEAR_TOKEN_TIMESTAMP:
            return {
                ...state,
                tokenTimestamp: null
            };

        default:
            return state;
    }
}

export default assinaturaDigital;
