import React, { useContext } from 'react';
import { Field } from 'formik';
import { SITUACAO } from '../../constantes';
import { SttFormControl, SttFormGroup, SttFormControlLabel, SttCheckbox, SttTranslateHook } from '@stt-componentes/core';
import { SITUACAO_EXAME } from '../fieldNames';

const SituacaoExame = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    
    return (
        <Field name={SITUACAO_EXAME}>
            {({
                field,
                form
            }) => (
                <SttFormControl variant="outlined">
                    <SttFormGroup row>
                        <SttFormControlLabel
                            control={
                                <SttCheckbox
                                    {...field}
                                    value={SITUACAO.VALIDO}
                                    color="primary"
                                    checked={form.values[SITUACAO_EXAME].includes(SITUACAO.VALIDO)}
                                />
                            }
                            label={strings.valido}
                        />
                        <SttFormControlLabel
                            control={
                                <SttCheckbox
                                    {...field}
                                    value={SITUACAO.INVALIDO}
                                    color="primary"
                                    checked={form.values[SITUACAO_EXAME].includes(SITUACAO.INVALIDO)}
                                />
                            }
                            label={strings.invalido}
                        />
                    </SttFormGroup>
                </SttFormControl>
            )}
        </Field>
    );
};

export default SituacaoExame;