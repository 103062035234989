import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import moment from 'moment';
import HttpStatus from 'http-status-codes';
import {
    SttButton,
    SttGrid,
    SttFileSelect,
    SttModal,
    SttText,
    SttAlerta,
    SttLoading,
    SttTable,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttDeleteButton,
    SttTranslateHook,
    SttTableHead
} from '@stt-componentes/core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    imgAtual: {
        maxHeight: '320px',
        maxWidth: '320px',
        margin: 'auto'
    }
}));

const Anexo = (props) => {
    const { exame, anexos, callback } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext); 
    const classes = useStyles(); 
    
    const [arquivo, setArquivo] = useState(null);
    const [salvando, setSalvando] = useState(false);
    // Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    const salvar = () => {
        setSalvando(true);
        const formData = new FormData();
        formData.append('anexo', arquivo);
        formData.append('modalidade', exame.sigla_modalidade);
        formData.append('data_exame', exame.data_exame);

        axios
            .post(`${EXAME_API_BASE_URL}/exame/${exame.id}/anexo`, formData, { headers: getHeaders(), 'Content-Type': 'multipart/form-data' })
            .then((resposta) => {
                setTipoAlerta('success');
                setTituloAlerta(strings.sucesso);
                setMensagemAlerta(strings.anexoInserido);
                setMostrarAlerta(true);
                setOpcoesAlerta([
                    { 
                        title: strings.ok, 
                        onClick: () => {
                            setMostrarAlerta(false);
                            fechar([ ...anexos, ...resposta.data ]);
                        } 
                    }
                ]);
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let mensagemAlerta = strings.erroGenerico;
                if (response) {
                    let msg = '';
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const dadosResposta = response.data;
                        let arrMensagem = [];
                        dadosResposta.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        mensagemAlerta = msg;
                    } else if (response.status === HttpStatus.REQUEST_TOO_LONG) {
                        // Extrai a parte numérica da mensagem, contendo o número
                        // de bytes permitidos
                        const bytes = response.data.message.replace(/\D/g, '');
                        mensagemAlerta = strings.erroTamanhoArquivo.replace('%', (bytes / 1000 / 1000).toFixed(0));
                    }
                }
                setTipoAlerta('error');
                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagemAlerta);
                setOpcoesAlerta([{ title: strings.ok, onClick: () => setMostrarAlerta(false) }]);
                setMostrarAlerta(true);
            })
            .finally(() => setSalvando(false));
    }

    const preRemover = (anexo) => {
        setTipoAlerta('alert');
        setTituloAlerta(strings.atencao);
        setMensagemAlerta(strings.confirmaRemocaoAnexo);
        setMostrarAlerta(true);
        setOpcoesAlerta([
            { 
                title: strings.sim, 
                onClick: () => {
                    setMostrarAlerta(false);
                    remover(anexo);
                } 
            },
            { 
                title: strings.nao, 
                onClick: () => setMostrarAlerta(false)
            }
        ]);
    }

    const remover = (anexo) => {
        setSalvando(true);
        axios
            .delete(`${EXAME_API_BASE_URL}/exame/${exame.id}/anexo/${anexo.id}`, { headers: getHeaders() })
            .then((response) => {
                const anexosRemanecentes = anexos.filter(item => item.url !== anexo.url);
                callback(anexosRemanecentes, false);
            })
            .catch(err => {
                console.log(err);
                setTipoAlerta('error');
                setTituloAlerta(strings.erro);
                setMensagemAlerta(strings.erroGenerico);
                setOpcoesAlerta([{ title: strings.ok, onClick: () => setMostrarAlerta(false) }]);
                setMostrarAlerta(true);
            })
            .finally(() => setSalvando(false));
    }

    const fechar = (anexos) => {
        setArquivo(null);
        callback(anexos, true);
    }

    return (
        <SttModal
            title={strings.adicionarAnexo}
            open={true}
            outModalClose={fechar}
            iconClose={fechar}
            maxWidth="sm"
            fullWidth={true}
            children={
                <SttGrid container spacing={3}>
                    <SttGrid item xs={12}>
                        <SttText size="small">
                            {exame.instituicao}
                        </SttText>
                        <SttText size="small">
                            {exame.descricao_exame}
                        </SttText>
                        <SttText size="small">
                            {exame.paciente}
                        </SttText>
                        <SttText size="small">
                            {moment(exame.data_exame).format('DD/MM/YYYY HH:mm:ss')}
                        </SttText>
                    </SttGrid>
                    {
                        anexos.length > 0 &&
                        <SttGrid item xs={12}>
                            <SttTable>
                                <SttTableHead>
                                    <SttTableRow>
                                        <SttTableCell width="95%">
                                            {strings.anexo}
                                        </SttTableCell>
                                        <SttTableCell width="5%">
                                            {strings.excluir}
                                        </SttTableCell>
                                    </SttTableRow>
                                </SttTableHead>
                                <SttTableBody>
                                    {
                                        anexos.map(anexo => (
                                            <SttTableRow key={anexo.url}>
                                                <SttTableCell width="95%">
                                                    {anexo.nome_arquivo}
                                                </SttTableCell>
                                                <SttTableCell width="5%" align="center">
                                                    <SttDeleteButton
                                                        onClick={() => preRemover(anexo)}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                    }
                                </SttTableBody>
                            </SttTable>
                        </SttGrid>
                    }
                    {
                        anexos.length === 0 &&
                        <SttGrid item xs={12}>
                            <SttFileSelect
                                labelInput={strings.arquivo}
                                onFileChange={event => {
                                    if (event) {
                                        setArquivo(event.target.files[0]);
                                    } else {
                                        setArquivo(null);
                                    }
                                }}
                                file={arquivo}
                                accept={"image/jpeg,image/png,application/pdf"}
                                inputprops={{
                                    name: 'arquivo',
                                    label: strings.anexo,
                                    value: arquivo?.name || '',
                                    required: true
                                }}
                            />
                        </SttGrid>
                    }
                    <SttGrid item xs={12}>
                        {
                            arquivo &&
                            <Carousel statusFormatter={(current, total) => `Imagem ${current} de ${total}`}>
                                {
                                    arquivo.type !== 'application/pdf' && 
                                    <div>
                                        <img src={URL.createObjectURL(arquivo)} className={classes.imgAtual} />
                                        <p className="legend">{arquivo.name}</p>
                                    </div>
                                }
                            </Carousel>

                        }
                    </SttGrid>
                    <SttAlerta
                        open={mostrarAlerta}
                        title={tituloAlerta}
                        message={mensagemAlerta}
                        type={tipoAlerta}
                        options={opcoesAlerta}
                        onClose={() => setMostrarAlerta(false)}
                    />
                    <div className={classes.carregando}>
                        <SttLoading
                            open={salvando}
                            text={strings.aguarde}
                        />
                    </div>
                </SttGrid>
            }
            footer={
                <div>
                    <SttButton variant="contained" color="primary" onClick={salvar} disabled={!arquivo}>
                        {strings.salvar}
                    </SttButton>
                    <SttButton variant="outlined" color="primary" onClick={fechar}>
                        {strings.fechar}
                    </SttButton>
                </div >
            }
        />
    )

}

Anexo.propTypes = {
    exame: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired
};

export default Anexo;