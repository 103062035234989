import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import Pesquisa from './pesquisa';
import Utils from '../../utils';
import {
    SttLoading,
    SttTranslateHook,
    SttAlerta
} from '@stt-componentes/core';
import { MODALIDADES_TELEDIAGNOSTICO, SIM } from 'src/common/Constants';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const Aba = (props) => {
    const { user, ...other } = props;
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [gerandoPdf, setGerandoPdf] = useState(false);
    const [openAlerta, setOpenAlerta] = useState(false);
    const [tituloAlerta, setTitulo] = useState(strings.atencao);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [opcoesAlerta, setOpcoesAlerta] = useState(
        [
            {
                title: strings.ok,
                onClick: () => {
                    setOpenAlerta(false);
                }
            }
        ]
    );

    const imprimir = (exame) => {
        if(exame.imprime_exame === SIM) {
            if(MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade)) {
                Utils.imprimirFn(exame, () => setGerandoPdf(false), true);
                setGerandoPdf(true);
                return; 
            }

            // Essa verificação funcionará somente para exames de radiologia
            setTitulo(strings.atencao);
            setMensagemAlerta(strings.mensagemImprimirImagensExame);
            setTipoAlerta('alert');
            setOpcoesAlerta([
                {
                    title: strings.sim,
                    onClick: () => {
                        setGerandoPdf(true);
                        Utils.imprimirFn(exame, () => setGerandoPdf(false), true);
                        setOpenAlerta(false);
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        setGerandoPdf(true);
                        Utils.imprimirFn(exame, () => setGerandoPdf(false), false);
                        setOpenAlerta(false);
                    }
                }
            ]);
            setOpenAlerta(true);
            return;
        }
        setGerandoPdf(true);
        Utils.imprimirFn(exame, () => setGerandoPdf(false), false);
    }

    const imprimirProtocolo = (exame) => {
        setGerandoPdf(true);
        Utils.imprimirProtocoloFn(exame, () => setGerandoPdf(false));
    }

    const imprimirMultiplosProtocolos = (exames) => {
        setGerandoPdf(true);
        Utils.imprimirMultiplosProtocolosFn(exames, () => setGerandoPdf(false));
    }

    return (
        <>
            <Switch>
                <Route path="/" children={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirMultiplosProtocolosFn={imprimirMultiplosProtocolos} />} />
            </Switch>
            <div className={classes.carregando}>
                <SttLoading
                    open={gerandoPdf}
                    text={strings.gerandoDocumento}
                />
            </div>
            <SttAlerta
                open={openAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={() => setOpenAlerta(false)}
            />
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(Aba);