import React, { useState, lazy, Suspense, memo, useContext } from 'react';
import { connect } from 'react-redux';
import {
    SttTabs,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const ModeloLaudo = lazy(() => import('../../componentes/administrativo/modelo-laudo/pesquisa'));
const CadastroAtalhos = lazy(() => import('../../componentes/administrativo/atalhos/pesquisa'));
const PendenciaAssinatura = lazy(() => import('../../componentes/administrativo/assinatura-digital/pendencias'));

const ModeloLaudoSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <ModeloLaudo {...props} />
        </Suspense>
    )
});

const CadastroAtalhosSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <CadastroAtalhos {...props} />
        </Suspense>
    )
});

const PendenciaAssinaturaSuspense = (props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <PendenciaAssinatura {...props} />
        </Suspense>
    )
};

const IndexAdministrativo = ({ assinaturaDigital }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const listaAbas = [{
        titulo: strings.modeloLaudo,
        conteudo: ModeloLaudoSuspense,
        key: 'ModeloLaudoSuspense',
        permanente: true
    }, {
        titulo: strings.cadastroLaudoAtalho,
        conteudo: CadastroAtalhosSuspense,
        key: 'CadastroAtalhosSuspense',
        permanente: true
    }];

    if (assinaturaDigital?.neoid?.ativo) {
        listaAbas.push({
            titulo: strings.laudoAguardandoAssinatura,
            conteudo: PendenciaAssinaturaSuspense,
            key: 'PendenciaAssinaturaSuspense',
            permanente: true
        });
    }

    const [abas] = useState(listaAbas);

    const [abaAtiva, setAbaAtiva] = useState('0');

    return (
        <SttTabs abas={abas}
            className={classes.wrapper}
            handleChangeAbaAtiva={setAbaAtiva}
            abaAtiva={abaAtiva}
            permanente={true}
            preload={false}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        assinaturaDigital: state.assinaturaDigital.config
    };
};

export default connect(mapStateToProps)(IndexAdministrativo);
