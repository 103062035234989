import axios from 'axios';
import { getHeaders } from '../request';
import { NotificationManager } from 'react-notifications';

const Utils = {

    urlApi: () => global.gConfig.url_base_exames,

    imprimirFn: (exame, callback, imprimeImagens) => {
        const idExameCodificado = Buffer.from(exame.id.toString()).toString('base64');

        const opcoes = {
            headers: getHeaders(),
            params: { 
                basename: global.gConfig.basename,
                modulo: global.gConfig.modulo_nome,
                versao: global.gConfig.modulo_versao
            }
        };

        if (imprimeImagens) {
            opcoes.params = { ...opcoes.params, imagens: true }
        }

        axios.get(`${Utils.urlApi()}/exame/${idExameCodificado}/imprimir`, opcoes)
            .then((response) => {
                if (response.data) {
                    window.open(response.data);
                }
            })
            .catch(err => {
                console.log(err);
                NotificationManager.error(err.message);
            })
            .finally(callback);
    },

    imprimirProtocoloFn: (exame, callback) => {
        const idExameCodificado = Buffer.from(exame.id.toString()).toString('base64');
        const params = {
            idExame: idExameCodificado,
            basename: global.gConfig.basename,
            v2: true
        };
        axios.get(`${Utils.urlApi()}/protocolo`, { params, headers: getHeaders() })
            .then((response) => {
                window.open(response.data);
            })
            .catch(err => {
                console.log(err);
                NotificationManager.error(err.message);
            })
            .finally(callback);
    },

    imprimirMultiplosProtocolosFn: (exames, callback) => {
        const idExameCodificado = Buffer.from(exames.map(es => es.id).join(',')).toString('base64');
        const params = {
            idExame: idExameCodificado,
            basename: global.gConfig.basename,
            v2: true
        };
        axios.get(`${Utils.urlApi()}/protocolo`, { params, headers: getHeaders() })
            .then((response) => {
                window.open(response.data);
            })
            .catch(err => {
                console.log(err);
                NotificationManager.error(err.message);
            })
            .finally(callback);
    },

    downloadFn: (exame, callback) => {
        const idCodificado = Buffer.from(`${exame.id}`).toString('base64');
        const urlDownload = `${Utils.urlApi()}/exame/${idCodificado}/download`;
        axios
            .get(urlDownload, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    Utils.baixarArquivo(response.data, `Estudo_${exame.paciente || exame.nome_paciente.replace(/\W+|\s+/g, '_')}.zip`);
                }
            })
            .catch(err => {
                console.log(err);
                NotificationManager.error(err.message);
            })
            .finally(callback);
    },

    baixarArquivo: (url, nome) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_parent');
        link.setAttribute('download', nome);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }
};

export default Utils;