import React, { useContext, useState, useEffect, useRef } from 'react';
import HttpStatus from 'http-status-codes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setTokenTimestamp } from '../../reducers/actions/assinatura-digital';
import { getHeaders } from '../../request';
import axios from 'axios';
import crypto from 'crypto-browserify';
import { ASSINATURA_DIGITAL } from 'src/common/Constants';
import { NotificationManager } from 'react-notifications';
import {
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';


const AssinaturaDigital = (props) => {
    const {
        assinaturaDigital,
        laudos,
        callback,
        setTokenTimestamp,
        escopo = ASSINATURA_DIGITAL.NEOID.ESCOPO.SINGLE_SIGNATURE
    } = props;

    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [authorizationToken, setAuthorizationToken] = useState(null);
    const [assinar, setAssinar] = useState(false);
    const [assinando, setAssinando] = useState(false);
    const popUpRef = useRef(null);

    /**
     * Inicializa o processo de assinatura. Se existe token de autorização e o
     * escopo for de sessão de assinatura, interrompe o fluxo de geração de token.
     */
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem(ASSINATURA_DIGITAL.NEOID.AUTHORIZATION_TOKEN));
        if (token) {
            const { scope } = token;
            if (scope === ASSINATURA_DIGITAL.NEOID.ESCOPO.SIGNATURE_SESSION) {
                setAuthorizationToken(token);
                setAssinar(true);
                return;
            }
        }

        preAssinar();

        return () => {
            localStorage.removeItem(ASSINATURA_DIGITAL.NEOID.CODE_VERIFIER);
            localStorage.removeItem(ASSINATURA_DIGITAL.NEOID.STATE);
            localStorage.removeItem(ASSINATURA_DIGITAL.NEOID.CONFIG);

            // Apenas remove o token se não for de sessão de assinatura            
            if (authorizationToken) {
                const { scope } = authorizationToken;
                if (scope !== ASSINATURA_DIGITAL.NEOID.ESCOPO.SIGNATURE_SESSION) {
                    localStorage.removeItem(ASSINATURA_DIGITAL.NEOID.AUTHORIZATION_TOKEN);
                }
            }
        }
    }, []);

    /**
     * Assina o documento
     */
    useEffect(() => {
        if (assinar) {
            if (authorizationToken) {
                setAssinando(true);

                const hashes = [];
                for (const laudo of laudos) {
                    // Para cada documento, cria o hash e prepara os dados para assinatura
                    const hash = crypto.createHash('sha256').update(laudo.html).digest().toString('base64');
                    hashes.push({
                        id: laudo.id,
                        alias: `Laudo do exame ${laudo.id_exame}`,
                        hash: hash,
                        hash_algorithm: ASSINATURA_DIGITAL.NEOID.ALGORITMO.SHA256,
                        signature_format: ASSINATURA_DIGITAL.NEOID.FORMATO.RAW
                    });
                }
                // Finalmente, assina os documentos
                const payload = { hashes };
                const headers = {
                    'Content-type': 'application/json',
                    Authorization: `${authorizationToken.token_type} ${authorizationToken.access_token}`
                }

                axios
                    .post(`${assinaturaDigital.neoid.url_base}/signature`, payload, { headers })
                    .then((response) => {
                        const { data: { signatures } } = response;
                        salvarAssinatura(signatures, hashes);
                    })
                    .catch(err => {
                        console.log(err);
                        const { response } = err;
                        let msg = strings.erroGenerico;
                        if (response) {
                            msg = response.data.msg;
                        }
                        setAssinando(false);
                        NotificationManager.error(msg);

                        if (response.status === HttpStatus.UNAUTHORIZED) {
                            setAuthorizationToken(null);
                            preAssinar();
                        }
                    });
            } else {
                finalizar();
            }
        }

    }, [assinar]);

    const salvarAssinatura = (assinaturas, hashes) => {
        const dados = estruturarDados(assinaturas, hashes);
        const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
        axios
            .post(`${EXAME_API_BASE_URL}/laudo/assinatura-digital`, dados, { headers: getHeaders() })
            .then((response) => {
                const qtd = assinaturas.length;
                const msg = qtd > 1 ? `${qtd} ${strings.laudosAssinados}` : strings.laudoAssinado;
                NotificationManager.success(msg);
            })
            .catch(err => {
                console.log(err);
                NotificationManager.error(strings.erroGenerico);
            })
            .finally(() => finalizar());
    }

    const estruturarDados = (assinaturas, hashes) => {
        const dados = [];
        for (const assinatura of assinaturas) {
            const hash = hashes.find(h => h.id === parseInt(assinatura.id)).hash;
            dados.push({
                id_laudo: parseInt(assinatura.id),
                hash: hash,
                assinatura: assinatura.raw_signature
            });
        }
        return dados;
    }

    const preAssinar = () => {
        // 1. Cria o Code Verifier, o Code Challenge e o state
        // https://datatracker.ietf.org/doc/html/rfc7636
        const codeVerifier = createCodeVerifier();
        localStorage.setItem(ASSINATURA_DIGITAL.NEOID.CODE_VERIFIER, codeVerifier);

        const codeChallenge = createCodeChallenge(codeVerifier);

        const state = base64Encode(generateRamdomString(10));
        localStorage.setItem(ASSINATURA_DIGITAL.NEOID.STATE, state);

        localStorage.setItem(ASSINATURA_DIGITAL.NEOID.CONFIG, JSON.stringify(assinaturaDigital));

        // 2. Inicia o processo de authorização do usuário através da API NeoID
        const parametros = new URLSearchParams({
            response_type: 'code',
            client_id: assinaturaDigital.neoid.client_id,
            code_challenge: codeChallenge,
            code_challenge_method: 'S256',
            scope: escopo,
            state: state,
            redirect_uri: assinaturaDigital.neoid.redirect_uri
        });
        const url = `${assinaturaDigital.neoid.url_base}/authorize?${parametros.toString()}`;
        openPopup(url);

        // 3. Obtém o token de autorização via local storage
        window.addEventListener('storage', function (event) {
            if (event.key === ASSINATURA_DIGITAL.NEOID.AUTHORIZATION_TOKEN) {
                setTokenTimestamp((new Date().getTime()));
                setAuthorizationToken(JSON.parse(event.newValue));
            }
        });
    }

    const openPopup = (url) => {
        // Trecho responsável por definir o posicionamento do popup
        const h = 650;
        const w = 500;
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        // Propriedades do popup
        const popupFeatures = `
            status=no,
            resizable=no,
            scrollbars=no,
            location=no,
            menubar=no,
            toolbar=no
            width=${w / systemZoom}, 
            height=${h / systemZoom}, 
            top=${top}, 
            left=${left}`;

        popUpRef.current = window.open(
            url,
            generateRamdomString(10),
            popupFeatures
        );

        if (popUpRef.current.focus) popUpRef.current.focus();

        let timer = setInterval(function () {
            if (popUpRef.current?.closed) {
                clearInterval(timer);
                setAssinar(true);
            }
        }, 500);
    }

    const generateRamdomString = (size) => {
        return crypto.randomBytes(size);
    }

    const createCodeVerifier = () => {
        const str = generateRamdomString(32);
        return base64Encode(str);
    }

    const createCodeChallenge = (codeVerifier) => {
        const hash = crypto
            .createHash('sha256')
            .update(codeVerifier)
            .digest();

        return base64Encode(hash);
    }

    const base64Encode = (str) => {
        return str
            .toString('base64')
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
    }

    const finalizar = () => {
        setAssinando(false);
        if (callback) {
            callback();
        }
    }

    return (
        <SttLoading open={assinando} text={strings.assinando} />
    );
}

AssinaturaDigital.propTypes = {
    laudos: PropTypes.array.isRequired,
    callback: PropTypes.func.isRequired,
    escopoAssinatura: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        assinaturaDigital: state.assinaturaDigital.config,
        tokenTimestamp: state.assinaturaDigital.tokenTimestamp
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTokenTimestamp: escopo => dispatch(setTokenTimestamp(escopo))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AssinaturaDigital);