import React, { useContext } from 'react';
import { FastField } from 'formik';
import { SttInput, SttTranslateHook } from '@stt-componentes/core';
import { DESCRICAO_EXAME } from '../fieldNames';

const DescricaoExame = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    return (
        <FastField name={DESCRICAO_EXAME}>
        {({
            field
        }) => (
            <SttInput
                {...field}
                placeholder={strings.placeholderSepareTermos}
                label={strings.termosDescricao}
            />
        )}
        </FastField>
    );
};

export default DescricaoExame;