import React, { useEffect, useState } from 'react';
import { SttProdutividadeLaudo, SttTitulo, SttGrid } from '@stt-componentes/core';
import axios from 'axios';
import { getHeaders } from 'src/request';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '6vh',
    },

}));

const Modalidade = ({ titulo, modalidade, instituicao }) => {
    const [mesAno] = useState({
        mes: new Date().getMonth() + 1,
        ano: new Date().getFullYear()
    });

    const [mesAnterior, setMesAnterior] = useState(
        {
            mes: new Date().getMonth(),
            ano: new Date().getFullYear()
        });

    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    const [dadosSemLaudoMesAtual, setDadosSemLaudoMesAtual] = useState([]);
    const [dadosDirecionadoMesAtual, setDadosDirecionadoMesAtual] = useState([]);
    const [dadosLaudado, setDadosLaudado] = useState([]);
    const [dadosDirecionado, setDadosDirecionado] = useState([]);
    const [dadosSemLaudo, setDadosSemLaudo] = useState([]);
    const [dadosLaudadoMesAtual, setDadosLaudadoMesAtual] = useState([]);
    const [dadosLaudadoSemana, setDadosLaudadoSemana] = useState([]);

    const classes = useStyles();

    const handleDateChange = (date) => {
        if (date) {
            const { mes, ano } = date;
            setMesAnterior({ mes, ano });
        }
    };

    useEffect(() => {
        axios
            .get(`${EXAME_API_BASE_URL}/produtividade/laudado`, {
                params: { idInstituicao: instituicao, mes: mesAno.mes, ano: mesAno.ano, idModalidade: modalidade },
                headers: getHeaders()
            })
            .then((response) => {
                const { data } = response;
                const soma = 0;
                const total = data.reduce((total, item) => total + item.total, soma);
                setDadosLaudadoMesAtual({ total });
                setDadosLaudadoSemana(data[0])
            })
            .catch(err => console.log(err));

        axios
            .get(`${EXAME_API_BASE_URL}/produtividade/sem-laudo`, {
                params: { idInstituicao: instituicao, mes: mesAno.mes, ano: mesAno.ano, idModalidade: modalidade },
                headers: getHeaders()
            })
            .then((response) => {
                setDadosSemLaudoMesAtual(response.data[0]);
            })
            .catch(err => console.log(err));

        axios
            .get(`${EXAME_API_BASE_URL}/produtividade/direcionado`, {
                params: { idInstituicao: instituicao, mes: mesAno.mes, ano: mesAno.ano, idModalidade: modalidade },
                headers: getHeaders()
            })
            .then((response) => {
                setDadosDirecionadoMesAtual(response.data[0]);
            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        axios
            .get(`${EXAME_API_BASE_URL}/produtividade/laudado`, {
                params: { idInstituicao: instituicao, mes: mesAnterior.mes, ano: mesAnterior.ano, idModalidade: modalidade },
                headers: getHeaders()
            })
            .then((response) => {
                const { data } = response;
                const soma = 0;
                const total = data.reduce((total, item) => total + item.total, soma);
                setDadosLaudado({ total });
            })
            .catch(err => console.log(err));

        axios
            .get(`${EXAME_API_BASE_URL}/produtividade/direcionado`, {
                params: { idInstituicao: instituicao, mes: mesAnterior.mes, ano: mesAnterior.ano, idModalidade: modalidade },
                headers: getHeaders()
            })
            .then((response) => {
                setDadosDirecionado(response.data[0]);
            })
            .catch(err => console.log(err));

        axios
            .get(`${EXAME_API_BASE_URL}/produtividade/sem-laudo`, {
                params: { idInstituicao: instituicao, mes: mesAnterior.mes, ano: mesAnterior.ano, idModalidade: modalidade },
                headers: getHeaders()
            })
            .then((response) => {
                setDadosSemLaudo(response.data[0]);
            })
            .catch(err => console.log(err));

    }, [mesAnterior]);

    return (
        <SttGrid container spacing={3} className={classes.container}>
            <SttGrid item xs={12}>
                {modalidade && <SttTitulo modulo={titulo} />}
            </SttGrid>
            <SttGrid item xs={12} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                {modalidade && (
                    <SttProdutividadeLaudo
                        semLaudo={dadosSemLaudo.total}
                        direcionados={dadosDirecionado.total}
                        laudados={dadosLaudado.total}
                        hasButton={true}
                        onDateChange={handleDateChange}
                    />
                )}
            </SttGrid>
            <SttGrid item xs={12} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                {modalidade && (
                    <SttProdutividadeLaudo
                        semLaudo={dadosSemLaudoMesAtual.total}
                        direcionados={dadosDirecionadoMesAtual.total}
                        laudados={dadosLaudadoMesAtual.total}
                        semana={dadosLaudadoSemana.total}
                    />
                )}
            </SttGrid>
        </SttGrid>
    );
};

export default Modalidade;