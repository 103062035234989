import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { setParametrosPesquisa as setParametrosPesquisaAction } from '../../../../reducers/actions/exame';
import { Field, useFormikContext } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import { temPerfilRBAC } from '../../../../secutity/acl';
import { VINCULO_PERFIL } from '../../constantes';
import { SttAutocomplete, SttTranslateHook } from '@stt-componentes/core';
import { MUNICIPIO, INSTITUICAO} from '../fieldNames';
import { PERFIL } from '../../../../common/Constants';

const Instituicao = (props) => {
    const { user, filtros, setParametrosPesquisa } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { values, setFieldValue } = useFormikContext();

    const [instituicoes, setInstituicoes] = useState([]);
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

    useEffect(() => {
        if (temPerfilRBAC(user, PERFIL.MEDICO_EXECUTOR)) {
            carregarInstituicoesVinculoExecutor();   
        }
    }, []);

    useEffect(() => {
        if (values[MUNICIPIO]) {
            axios.get(`${UTILITARIOS_API_BASE_URL}/instituicao-equipamento?municipio=${values[MUNICIPIO].id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        tratarFiltroInstituicao(values[MUNICIPIO], itens);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setInstituicoes([]);
                });
        } else {
            if (temPerfilRBAC(user, PERFIL.MEDICO_EXECUTOR)) {
                carregarInstituicoesVinculoExecutor();
            } else {
                setInstituicoes([]);
            }
        }
    }, [values[MUNICIPIO]]);

    const carregarInstituicoesVinculoExecutor = () => {
        const perfilExecutor = user.perfisRBAC.filter(perfil => perfil.identificador === PERFIL.MEDICO_EXECUTOR);
        const listaInst = perfilExecutor[0].instituicoes;
        setInstituicoes(listaInst);
        if (listaInst.length === 1) {
            setFieldValue(INSTITUICAO, listaInst[0]);
        }
    }

    const tratarFiltroInstituicao = (municipio, instituicoes) => {
        if (temPerfilRBAC(user, PERFIL.MEDICO_EXECUTOR) || 
            temPerfilRBAC(user, PERFIL.MEDICO_VISUALIZADOR) || 
            temPerfilRBAC(user, PERFIL.MEDICO_RESIDENTE) || 
            temPerfilRBAC(user, PERFIL.TECNICO)) {
            const perfisInstituicao = user.perfisRBAC.filter(perfil => perfil.vinculo === VINCULO_PERFIL.INSTITUICAO);
            const instituicoesHabilitadas = [];
            perfisInstituicao.forEach(perfil => {
                perfil.instituicoes.forEach(instituicao => {
                    if (!instituicoesHabilitadas.some(i => i.id === instituicao.id)) {
                        instituicoesHabilitadas.push(instituicao);
                    }
                });
            });

            const listaInstituicoes = [];
            instituicoesHabilitadas.forEach(inst => {
                if (!listaInstituicoes.includes(inst.id)) {
                    // Lista de ID's das instituições
                    listaInstituicoes.push(inst.id);
                }
            });
            // Carrega o combobox com as instituições
            const listaHospitais = instituicoes.filter(instituicao => listaInstituicoes.includes(instituicao.id));
            setInstituicoes(listaHospitais);
            // Se tem vínculo com apenas uma instituição, seleciona no filtro
            if (listaInstituicoes.length === 1) {
                const instituicao = listaHospitais[0];
                const uf = {
                    id: municipio.id_estado,
                    nome: municipio.nome_estado,
                    sigla: municipio.sigla_estado
                };
                setParametrosPesquisa({ ...filtros, uf: uf, municipio: municipio, instituicao: instituicao });
            }
        } else {
            setInstituicoes(instituicoes);
        }     
    }

    return (
        <Field name={INSTITUICAO}>
            {({
                field: { name, value, onBlur },
                form: { setFieldValue }
            }) => (
                <SttAutocomplete
                    inputprops={{
                        name: name,
                        label: strings.instituicao
                    }}
                    getOptionLabel={option => option?.nome || ''}
                    getOptionSelected={(option, val) => option?.id === val?.id}
                    options={instituicoes}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e, item) => {
                        setFieldValue(INSTITUICAO, item || null);
                    }}
                    disabled={!temPerfilRBAC(user, PERFIL.MEDICO_EXECUTOR) && !values[MUNICIPIO]}
                />
            )}
        </Field>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filtros: state.exame.filtros
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Instituicao);