module.exports = {
    aguardandoAutorizador: 'Encaminhada',
    aguardandoOperador: 'Nova',
    alteracaoApac: 'Alteração de processo de APAC',
    alterarAutorizador: 'Alterar autorizador',
    anoMesProcedencia: 'Ano e mês de procedência',
    apacContinuidade: 'APAC continuidade',
    apacPadrao: 'Solicitação padrão',
    autorizacao: 'Autorização',
    autorizado: 'Aprovado',
    autorizadoAdministrador: 'Autorizado pelo administrador',
    autorizadoOperador: 'Autorizado pelo operador',
    autorizadoPeloMedicoAutorizador: 'Autorizado',
    cancelado: 'Cancelado',
    cancelamento: 'Cancelamento',
    carregandoInstituicoesAutorizadoras: 'Carregando instituições autorizadoras',
    carregandoInstituicoesSolicitantes: 'Carregando instituicões solicitantes',
    cid10Secundario: 'CID-10 Secundário',
    cidadeAutorizadora: 'Cidade autorizadora',
    cidadeSolicitante: 'Cidade solicitante',
    cnsPaciente: 'CNS do paciente',
    codigoProcedimento: 'Código do procedimento',
    complementoProcedencia: 'Complemento da procedência',
    complexosRegulatorios: 'Complexos regulatórios',
    cpfPaciente: 'CPF do paciente',
    confirmarSalvarFuncionariosComplexoRegulatorio: 'Você deseja vincular estes autorizadores ao complexo regulatório escolhido?',
    dadosProcedimento: 'Dados do procedimento',
    dadosSigtap: 'Dados SIGTAP',
    dataAutorizacao: 'Data da autorização',
    dataFinalAutorizacao: 'Data final da autorização',
    dataInicialAutorizacao: 'Data inicial da autorização',
    dataValidade: 'Data de validade',
    dataFinalValidade: 'Data final de validade',
    mensagemDevolucao: 'Para quem você deseja devolver este processo?',
    descricaoProcedimento: 'Descrição do procedimento',
    devolucao: 'Devolução',
    devolvido: 'Devolvido',
    emAnalise: 'Análise',
    encaminharAutorizador: 'Encaminhar ao autorizador',
    erroAlterarSolicitacao: 'Houve um erro ao editar a solicitação da APAC. Tente novamente mais tarde.',
    erroImprimirProcesso: 'Ocorre um erro ao imprimir o processo. Tente novamente mais tarde.',
    erroIniciarNovaSolicitacao: 'Não foi possível inicializar a solicitação de APAC. Tente novamente mais tarde.',
    erroPesquisaProcedimentoSigtap: 'Houve um erro na busca de procedimentos SIGTAP. Tente novamente mais tarde.',
    erroRecuperacaoFuncionarioComplexoRegulatorio: 'Ocorreu um erro ao recuperar os funcionários do complexo regulatório. Por favor, tente mais tarde.',
    erroRecuperacaoMotivoCancelamento: 'Ocorreu um erro ao recuperar os motivos de cancelamento. Por favor, tente mais tarde.',
    erroRecuperacaoMotivoDevolucao: 'Ocorreu um erro ao recuperar os motivos de devolução. Por favor, tente mais tarde.',
    examesComplementares: 'Exames complementares',
    folhaAutorizacao: 'Folha de autorização do sistema de controle AC',
    funcionarioAdministrador: 'Funcionário administrador',
    funcionarioAutorizador: 'Funcionário autorizador',
    funcionarioSolicitante: 'Funcionário solicitante',
    iniciandoSolicitacao: 'Iniciando solicitação',
    instituicaoAutorizadora: 'Instituição autorizadora',
    instituicaoExecutora: 'Instituição executora',
    instrucaoVinculoAutorizadorComplexoRegulatorio: `<span>- Parametrização dos profissionais autorizadores nos complexos regulatórios.</span>
        <span>- A partir dessa tela, é possível realizar o cadastro dos profissionais autorizadores nos diferentes complexos regulatórios do sistema.</span>
        <span>- A partir dessa tela, também é possível descadastrar o profissional de um complexo regulatório previamente cadastrado.</span>`,
    invalidado: 'Invalidado',
    invalidar: 'Invalidar',
    justificativa: 'Justificativa',
    justificativaProcedimento: 'Justificativa do procedimento',
    justificativaSolicitacao: 'Justificativa da solicitação',
    liberacaoUnacon: 'Conforme DELIBERAÇÃO 092/CIB/2019 - A inserção dos pedidos de PET-CT no módulo ambulatorial, deve ser feita pela Unidade Hospitalar solicitante (UNACON/CACON).',
    medicoAutorizador: 'Médico autorizador',
    mensagemAberturaProcesso: 'Você deseja iniciar uma nova solicitação de APAC? Ao confirmar, um novo rascunho será criado.',
    motivoDevolucaoPadrao: `    Trata-se de reivindicação, ao SUS, de exame tipo tomografia por emissão de pósitrons (PET-CT ou PET Scan), por (preencher o diagnóstico da doença). A matéria é regida pela Portaria 1.340, de 1º. de dezembro de 2014, do Ministério da Saúde. A PET-CT não é um exame para rastreamento, para confeccionar um primeiro diagnóstico, para mera investigação inicial da existência ou não de implantes secundários, nem para fazer diagnóstico diferencial em situações duvidosas. Ela amplia e refina as informações sobre diagnósticos já confirmados através de outros métodos.
        Não houve aprovação pela Comissão Nacional de Incorporação de Tecnologias pelo SUS (CONITEC) à incorporação da tecnologia em casos deste tipo, com este diagnóstico, por precariedade de evidências científicas favoráveis quanto à sua eficácia, eficiência e efetividade. Há outras investigações pelas quais a PET-CT pode ser intercambiada. O procedimento, portanto, neste caso, não pode ser realizado pelo SUS. É substituível por outros exames disponíveis.
        Não há protocolo aprovado pelo Ministério da Saúde, concedendo o exame, para a indicação diagnóstica constante no pedido atual, pois ela não está coberta pelas portarias nos. 7 e 9, de 22 de abril de 2014, nem pela portaria no. 8, de 14 de abril de 2014, da Secretaria de Ciência, Tecnologia e Insumos Estratégicos do Ministério da Saúde. O procedimento, para este diagnóstico, não está padronizado na Tabela SIGTAP ou nas listas de procedimentos aprovadas na Comissão Intergestores Tripartite, atuante em Brasília. O pedido não cabe na Portaria 1.340, de 01/12/2014 do Ministério da Saúde. Sua concessão feriria, pois, o Art. 19-M da Lei 8.080/90.
        A PET-CT, para esta indicação, além de não fazer parte da RENASES, não compõe as listas de procedimentos do SUS. Para incluí-la no rol dos procedimentos, caso venham a ocorrer novos dados científicos, as entidades de pesquisa precisarão montar e aprovar processo, junto à CONITEC, no Ministério da Saúde, em Brasília, segundo os ditames do Decreto no. 7.646, de 21 de dezembro de 2011, da Lei no. 12.401, de 28/04/2011 e da Lei 8.080 de 19 de setembro de 1990. Não cabe, pois, ao Executivo Estadual, ao Judiciário, ou a Prefeituras realizar a incorporação desta tecnologia ao sistema público de saúde, de forma alheia à Comissão Nacional de Incorporação de Tecnologias (CONITEC) e de forma alheia à legislação.`,
    anexosObrigatoriosNovaApac: 'A adição do histórico (SGPe), do laudo da APAC, dos exames, do SISREG e dos documentos pessoais é obrigatória',
    anexosObrigatoriosApacContinuidade: 'A adição do laudo da APAC é obrigatória',
    necessitaLaudoMedicoExamesComplementares: 'A adição do laudo médico e do exame complementar é obrigatória',
    necessitaAnexo: 'Ao menos um anexo é obrigatório',
    nomeAutorizador: 'Nome do autorizador',
    novaSolicitacao: 'Nova solicitação',
    confirmarAutorizacao: 'Confirma a autorização deste processo?',
    numeroAcaoJudicial: 'Número da ação judicial',
    numeroAtendimentoUnacon: 'Número do atendimento na UNACON',
    numeroAutorizacaoControleAC: 'Número de autorização de controle AC',
    numeroSolicitacao: 'Número da solicitação',
    numeroSolicitacaoSisreg: 'Número de solicitação do SISREG',
    outros: 'Outros',
    pesquisaAPAC: 'Pesquisa de processos',
    procedenciaPedido: 'Procedência do pedido',
    procedenciaProcesso: 'Procedência do processo',
    procedimentoSolicitacaoPadrao: 'Detectamos que você está tentando solicitar um procedimento que já se encontra na lista de procedimentos auto-reguláveis. Por favor, utilize a lista para continuar.',
    processoApac: 'Processo de APAC',
    processoCancelado: 'Processo cancelado',
    processoDevolvido: 'Processo devolvido',
    processosSelecionados: 'processos selecionados.',
    quantidade: 'Quantidade',
    rascunho: 'Rascunho',
    rascunhoSalvoSucesso: 'Rascunho salvo com sucesso',
    resumoAnamnese: 'Resumo da anamnese e exame físico',
    devolverOperador: 'Operador',
    devolverSolicitante: 'Solicitante',
    resumoExameFisico: 'Resumo do exame físico',
    salvandoRascunho: 'Salvando rascunho',
    sequencialSemLetras: 'O número sequencial do processo não deve possuir letras',
    solicitacaoEnviada: 'Solicitação enviada com sucesso.',
    solicitacaoNovoApac: 'Solicitação de APAC',
    sucessoOperacao: 'Operação realizada com sucesso',
    textoConfirmarEdicaoSolicitacao: 'Deseja salvar os dados da alteração?',
    textoConfirmarEnvio: 'Deseja encaminhar a solicitação?',
    textoConfirmarRascunhoSolicitacao: 'Deseja salvar o rascunho da solicitação?',
    tipoProcesso: 'Tipo de processo',
    tipoSolicitacao: 'Tipo de solicitação',
    vinculoAutorizadorComplexosRegulatorios: 'Vínculo de autorizadores aos complexos regulatórios',
    alterarDataValidade: 'Alterar data de validade',
    verificamosApacProcessoPaciente: 'Acesse AQUI o histórico de processos de APAC já aprovadas para esse paciente.',
    atencaoAdicaoAnexos: tipoSolicitacao => tipoSolicitacao === 'P' ? 'Anexe aqui o processo: histórico do SGPe, laudo médico, exames obrigatórios, comprovante do SISREG e documentos pessoais (RG, CPF, comprovante de residência). Todos são obrigatórios.' : 'Anexe aqui o processo: laudo médico (obrigatório) e exames complementares (não obrigatório).',
    solicitarNumeroApac: 'Solicitar número da APAC',
    confirmacaoSolicitarNumeroApac: 'Você deseja solicitar um número para este processo de APAC?',
    erroSolicitarNumeroApac: 'Ocorreu um erro ao gerar o número para este processo'
};