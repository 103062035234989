import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import moment from 'moment';
import HttpStatus from 'http-status-codes';
import {
    SttButton,
    SttGrid,
    SttInput,
    SttModal,
    SttText,
    SttAlerta,
    SttLoading,
    SttAutocomplete,
    SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const Direcionamento = (props) => {
    const { exame, callback } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext); 
    const classes = useStyles(); 
    
    const [executores, setExecutores] = useState([]);
    const [executor, setExecutor] = useState(null);
    const [justificativa, setJustificativa] = useState(null);
    const [salvando, setSalvando] = useState(false);
    const [tituloPopup, setTituloPopup] = useState(strings.direcionarExameLaudo);
    const [formValido, setFormValido] = useState(false);
    // Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

    const handleChangeJustificativa = (event) => {
        setJustificativa(event.target.value.trim());
    }

    useEffect(() => {
        if (exame.id_funcionario_direcionado) {
            setTituloPopup(strings.devolverListaGeral);
            return;
        }
        axios
            .get(
                `${UTILITARIOS_API_BASE_URL}/medico-executor/instituicao/${exame.id_instituicao}/modalidade/${exame.id_modalidade}`,
                { headers: getHeaders() }
            )
            .then((response) => {
                if (response) {
                    setExecutores(response.data);
                }
            })
            .catch(err => console.log(err));
    }, [exame]);

    useEffect(() => {
        if (exame.id_funcionario_direcionado && justificativa) {
            setFormValido(true);
        } else {
            setFormValido(executor && justificativa);
        }
    }, [justificativa, executor]);

    const salvar = () => {
        setSalvando(true);
        const dados = {
            justificativa,
            executor
        };
        axios
            .put(`${EXAME_API_BASE_URL}/exame/${exame.id}/direcionar-laudo`, dados, { headers: getHeaders() })
            .then((resposta) => {
                let msg = strings.exameDirecionado;
                if (exame.id_funcionario_direcionado) {
                    msg = strings.exameDevolvidoListaGeral;
                }
                setTipoAlerta('success');
                setTituloAlerta(strings.sucesso);
                setMensagemAlerta(msg);
                setMostrarAlerta(true);
                setOpcoesAlerta([
                    { 
                        title: strings.ok, 
                        onClick: () => {
                            setMostrarAlerta(false);
                            fechar();
                        } 
                    }
                ]);
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let mensagemAlerta = strings.erroGenerico;
                if (response) {
                    let msg = '';
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const dadosResposta = response.data;
                        let arrMensagem = [];
                        dadosResposta.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        mensagemAlerta = msg;
                    }
                }
                setTipoAlerta('error');
                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagemAlerta);
                setOpcoesAlerta([{ title: strings.ok, onClick: () => setMostrarAlerta(false) }]);
                setMostrarAlerta(true);
            })
            .finally(() => setSalvando(false));
    }

    const fechar = () => {
        setJustificativa(null);
        setExecutor(null);
        callback();
    }

    return (
        <SttModal
            title={tituloPopup}
            open={true}
            outModalClose={fechar}
            iconClose={fechar}
            maxWidth="sm"
            fullWidth={true}
            children={
                <SttGrid container spacing={3}>
                    {
                        !exame.id_funcionario_direcionado &&
                        <SttGrid item xs={12}>
                            <SttAutocomplete 
                                inputprops={{
                                    name: 'executores',
                                    label: strings.medicoExecutor,
                                    required: true
                                }}
                                getOptionLabel={option => option && option.nome || ''}
                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                options={executores}
                                value={executor}
                                onChange={(e, item) => {
                                    setExecutor(item);
                                }}
                            />
                        </SttGrid>
                    }
                    <SttGrid item xs={12}>
                        <SttInput 
                            multiline
                            minRows={4}
                            required
                            label={strings.justificativa}
                            onChange={handleChangeJustificativa}
                        />
                    </SttGrid>
                    <SttAlerta
                        open={mostrarAlerta}
                        title={tituloAlerta}
                        message={mensagemAlerta}
                        type={tipoAlerta}
                        options={opcoesAlerta}
                        onClose={() => setMostrarAlerta(false)}
                    />
                    <div className={classes.carregando}>
                        <SttLoading
                            open={salvando}
                            text={strings.aguarde}
                        />
                    </div>
                </SttGrid>
            }
            footer={
                <div>
                    <SttButton variant="contained" color="primary" onClick={salvar} disabled={!formValido}>
                        {strings.salvar}
                    </SttButton>
                    <SttButton variant="outlined" color="primary" onClick={fechar}>
                        {strings.fechar}
                    </SttButton>
                </div >
            }
        />
    )

}

Direcionamento.propTypes = {
    exame: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired
};

export default Direcionamento;