import React, { useContext } from 'react';
import { FastField } from 'formik';
import { SttTranslateHook, SttInputProtocol } from '@stt-componentes/core';
import { PROTOCOLO } from '../fieldNames';

const Protocolo = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    
    return (
        <FastField name={PROTOCOLO}>
            {({
                field
            }) => (
                <SttInputProtocol
                    label={strings.protocolo}
                    {...field}
                />
            )}
        </FastField>
    );
};

export default Protocolo;