import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import { getHeaders } from '../../request';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { values } from './initialValues';
import ModalConfirmacao from './modalConfirmacao';
import SttCid10 from '@stt-componentes/cid10';
import SttDecs from '@stt-componentes/decs';
import { SttExamesCkEditor } from '@stt-componentes/ckeditor';
import { useMoment } from 'src/hooks';
import { modeloPadrao, modeloPersonalizado } from './modelo-laudo';
import { styles } from './ckeditor-styles';
import juice from 'juice';
import validationSchema from './validationSchema';
import BotaoPublicar from './publicacao';
import { NotificationManager } from 'react-notifications';
import {
    PROFISSIONAL_DIRECIONADO,
    CONTEUDO_TEXTUAL,
    DESCRICAO,
    CONCLUSAO
} from './fieldNames';
import { v4 as uuidv4 } from 'uuid';

import {
    SttExpansionPanel,
    SttGrid,
    SttButton,
    SttLoading,
    SttHeading,
    SttDivider,
    SttTextItem,
    SttFormLabel,
    SttAlerta,
    SttFormHelperText,
    SttAutocomplete,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import './ckeditor.css';

const CALCULAR_FORMULAS = true;

const useStyles = makeStyles(theme => ({
    conteinerDecs: {
        paddingTop: theme.spacing(2)
    },
    tabs: {
        padding: '0'
    },
    titulo: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column'
    },
    textItemWrapper: {
        padding: 0
    },
    ckeditorLabel: {
        marginBottom: theme.spacing(1),
    },
    divider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    }
}));

const FormLaudo = (props) => {
    useSignals();
    const { user, exame, laudoCopia } = props;

    const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const moment = useMoment();

    const [modeloLaudo, setModeloLaudo] = useState([]);
    const [atalhos, setAtalhos] = useState([]);
    const [executores, setExecutores] = useState([]);
    const [modelosUsuario, setModelosUsuario] = useState([]);
    const [modelosInstituicao, setModelosInstituicao] = useState([]);
    const [modeloUsuario, setModeloUsuario] = useState(null);
    const [modeloInstituicao, setModeloInstituicao] = useState(null);
    const [textoLaudo, setTextoLaudo] = useState({});

    // Controles do modal de confirmação
    const [confirmar, setConfirmar] = useState(false);
    const [laudo, setLaudo] = useState(null);

    // Controles do formulário
    const [loadEditor, setLoadEditor] = useState(false);
    const [initialValues, setInitialValues] = useState(null);
    const [alerta, setAlerta] = useState(false);
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('');
    const [onCloseAlerta, setOnCloseAlerta] = useState(null);

    const editores = useSignal([]);

    useEffect(() => {
        if (laudoCopia) {
            const modelo = modeloPersonalizado(laudoCopia);
            setModeloLaudo(modelo);
        }
    }, [laudoCopia]);

    useEffect(() => {
        if (exame) {
            if (!exame.laudo) {
                setModeloLaudo(modeloPadrao);
            } else {
                const modelo = modeloPersonalizado(exame.laudo);
                const novoModelo = mergeModelos(modelo, modeloPadrao);
                setModeloLaudo(novoModelo);
            }

            // Carrega lista de modelos de laudo da instituicao
            axios
                .get(
                    `${EXAMES_API_BASE_URL}/modelo-laudo/instituicao/${exame.id_instituicao}/${exame.id_modalidade}`,
                    { headers: getHeaders() }
                )
                .then((response) => {
                    if (response) {
                        setModelosInstituicao(response.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                    NotificationManager.error(strings.erroCarregarModelosInstituicao);
                });

            // Carrega lista de modelos de laudo do funcionário
            axios
                .get(
                    `${EXAMES_API_BASE_URL}/modelo-laudo/funcionario/${user.idFuncionario}/${exame.id_modalidade}`,
                    { headers: getHeaders() }
                )
                .then((response) => {
                    if (response) {
                        const { data: { data: { itens } } } = response
                        setModelosUsuario(itens);
                    }
                })
                .catch(err => {
                    console.log(err);
                    NotificationManager.error(strings.erroCarregarModelosUsuario);
                });

            // Carrega a lista de executores para direcionamento do laudo
            axios
                .get(
                    `${UTILITARIOS_API_BASE_URL}/medico-executor/instituicao/${exame.id_instituicao}/modalidade/${exame.id_modalidade}`,
                    { headers: getHeaders() }
                )
                .then((response) => {
                    if (response) {
                        setExecutores(response.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                    NotificationManager.error(strings.erroCarregarMedicosExecutores);
                });

            // Carrega lista de atalhos para laudo
            axios
                .get(
                    `${global.gConfig.url_base_exames}/laudo/atalho`,
                    { headers: getHeaders() }
                )
                .then(response => {
                    if (response) {
                        const { data: { data: { itens } } } = response;
                        setAtalhos(itens);
                    }
                })
                .catch(err => {
                    console.log(err);
                    NotificationManager.error(strings.erroCarregarAtalhos);
                })
                .finally(() => setLoadEditor(true));
        }
    }, [exame]);

    useEffect(() => {
        setTextoLaudo({});
        setarIdentificadorUnicoModelos(modeloLaudo);
        setInitialValues(values(exame, modeloLaudo, moment().unix()));
    }, [modeloLaudo]);

    const callbackCancelar = () => {
        setConfirmar(false);
    }

    const handleChangeModeloUsuario = (event, modelo) => {
        setModeloUsuario(modelo);
        setModeloInstituicao(null);
        carregarModeloUsuario(modelo);
    }

    const handleChangeModeloInstituicao = (event, modelo) => {
        setModeloInstituicao(modelo);
        setModeloUsuario(null);
        carregarModeloInstituicao(modelo);
    }

    const adicionarModeloAoModeloAtual = (modeloSelecionado) => {
        const modeloAtual = [...modeloLaudo];
        const novoModelo = mergeModelos(modeloAtual, modeloSelecionado);
        setModeloLaudo(novoModelo);
    }

    const mergeModelos = (modelo1, modelo2) => {
        modelo1.forEach(item => {
            if (textoLaudo[item.secao.toLowerCase()]) {
                item.conteudo = textoLaudo[item.secao.toLowerCase()];
            }
        });

        modelo2.forEach((itemModelo2, indiceItemModelo2) => {
            const indiceItemModeloAtual = modelo1.findIndex(itemModeloAtual => itemModeloAtual.secao.toLowerCase() === itemModelo2.secao.toLowerCase());
            if (indiceItemModeloAtual !== -1) {
                modelo1.splice(indiceItemModeloAtual, 1, { secao: itemModelo2.secao, conteudo: `${modelo1[indiceItemModeloAtual].conteudo} ${itemModelo2.conteudo}` });
            } else {
                modelo1.splice(indiceItemModelo2, null, itemModelo2);
            }
        });
        return modelo1;
    }

    const preSelecionarModeloLaudo = (modelo, callbackAdicionar, callbackSubstituir) => {
        setTituloAlerta(strings.oQueDesejaFazer);
        setOpcoesAlerta([
            {
                title: strings.adicionar,
                onClick: () => {
                    callbackAdicionar(modelo);
                    setAlerta(false);
                }
            },
            {
                title: strings.substituir,
                onClick: () => {
                    callbackSubstituir(modelo);
                    setAlerta(false);
                }
            }
        ]);
        setMensagemAlerta(strings.desejaAdicionarOuSubstituir);
        setTipoAlerta('alert');
        setOnCloseAlerta(null);
        setAlerta(true);
    }

    const carregarModeloUsuario = (modelo) => {
        editores.value = [];
        if (!modelo) {
            setModeloLaudo(modeloPadrao);
            return;
        }
        axios
            .get(
                `${EXAMES_API_BASE_URL}/modelo-laudo/${modelo.id}/funcionario`,
                { headers: getHeaders() }
            )
            .then((response) => {
                if (response) {
                    const dados = response.data;
                    preSelecionarModeloLaudo(dados, adicionarModeloAoModeloAtual, setModeloLaudo);
                }
            })
            .catch(err => console.log(err));
    }

    const carregarModeloInstituicao = (modelo) => {
        editores.value = [];

        if (!modelo) {
            setModeloLaudo(modeloPadrao);
            return;
        }
        axios
            .get(
                `${EXAMES_API_BASE_URL}/modelo-laudo/${modelo.id}/instituicao`,
                { headers: getHeaders() }
            )
            .then((response) => {
                if (response) {
                    const dados = response.data;
                    preSelecionarModeloLaudo(dados, adicionarModeloAoModeloAtual, setModeloLaudo);
                }
            })
            .catch(err => console.log(err));
    }

    const transformarCssCKEDITOR = (data) => {
        for (const campo in data) {
            data[campo] = juice.inlineContent(data[campo], styles);
        }
    }

    const setarIdentificadorUnicoModelos = (modelos) => {
        for (const modelo of modelos) {
            modelo.id = uuidv4();
        }
    }

    const atualizarEditores = (editor) => {
        setTimeout(() => {
            editores.value = [...editores.value, editor];
        }, 0);
    }

    const calcularFormulas = () => {
        return new Promise((resolve) => {
            if (CALCULAR_FORMULAS) {
                for (const editor of editores.value) {
                    editor.execute('calcularFormulas');
                }
            }
            resolve();
        })
    }
    return (
        initialValues && loadEditor &&
        <>
            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <SttHeading variant="h3" color="primary" align="center" className={classes.titulo}>
                        <span>{`${exame.descricao_exame} - ${exame.data_exame}`}</span>
                        <span>{`${exame.nome_instituicao} - ${exame.nome_cidade}/${exame.sigla_uf}`}</span>
                    </SttHeading>
                </SttGrid>
            </SttGrid>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                    const laudo = structuredClone(data);
                    transformarCssCKEDITOR(laudo.conteudoTextual);
                    axios
                        .post(`${EXAMES_API_BASE_URL}/laudo/resumo`, laudo, { headers: getHeaders() })
                        .then((response) => {
                            setLaudo(response.data.data);
                            setConfirmar(true);
                        })
                        .catch(err => {
                            console.log(err);
                            const { response } = err;
                            let msg = strings.erroGenerico;
                            if (response) {
                                if (response.status === HttpStatus.BAD_REQUEST) {
                                    let arrMensagem = [];
                                    response.data.errors.forEach(error => {
                                        arrMensagem.push(`- ${error.message}`);
                                    });
                                    msg = arrMensagem.join('\n');
                                    setTituloAlerta(response.data.message);
                                } else {
                                    setTituloAlerta(strings.erro);
                                }
                            } else {
                                setTituloAlerta(strings.erro);
                            }
                            setOpcoesAlerta([{
                                title: strings.ok,
                                onClick: () => setAlerta(false)
                            }]);
                            setMensagemAlerta(msg);
                            setTipoAlerta('error');
                            setOnCloseAlerta(null);
                            setAlerta(true);
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {
                    ({
                        values: val,
                        errors,
                        isSubmitting,
                        submitCount,
                        setFieldValue,
                        handleSubmit
                    }) => {

                        return (
                            <div>
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttExpansionPanel
                                        title={strings.paciente}
                                        compact
                                        children={
                                            <div>
                                                <SttTextItem title={strings.nome} content={exame.nome_paciente} wrapperClass={classes.textItemWrapper} />
                                                {
                                                    exame.data_nascimento_paciente_formatada &&
                                                    <SttTextItem title={strings.dataNascimento} content={exame.data_nascimento_paciente_formatada} wrapperClass={classes.textItemWrapper} />
                                                }
                                                {
                                                    exame.idade_paciente &&
                                                    <SttTextItem title={strings.idade} content={exame.idade_paciente} />
                                                }
                                                {
                                                    exame.sexo_paciente &&
                                                    <SttTextItem title={strings.sexo} content={exame.sexo_paciente} wrapperClass={classes.textItemWrapper} />
                                                }
                                            </div>
                                        }
                                    />
                                    {
                                        exame.indicacao_clinica &&
                                        <>
                                            <SttDivider className={classes.divider} />
                                            <SttExpansionPanel
                                                title={strings.indicacaoClinica}
                                                compact
                                                children={
                                                    <SttTextItem title={strings.indicacao} content={exame.indicacao_clinica} wrapperClass={classes.textItemWrapper} />
                                                }
                                            />
                                        </>
                                    }
                                    <SttDivider className={classes.divider} />
                                    <SttExpansionPanel
                                        title={strings.laudo}
                                        compact
                                        children={
                                            <SttGrid container spacing={3}>
                                                {
                                                    modelosUsuario.length > 0 &&
                                                    <SttGrid item xs={12} sm={6}>
                                                        <SttAutocomplete
                                                            inputprops={{
                                                                name: 'modeloUsuario',
                                                                label: strings.modelosLaudoUsuario
                                                            }}
                                                            getOptionLabel={option => option && option.descricao || ''}
                                                            getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                            options={modelosUsuario}
                                                            value={modeloUsuario}
                                                            onChange={handleChangeModeloUsuario}
                                                        />
                                                    </SttGrid>
                                                }
                                                {
                                                    modelosInstituicao.length > 0 &&
                                                    <SttGrid item xs={12} sm={6}>
                                                        <SttAutocomplete
                                                            inputprops={{
                                                                name: 'modeloInstituicao',
                                                                label: strings.modelosLaudoInstituicao
                                                            }}
                                                            getOptionLabel={option => option && option.descricao || ''}
                                                            getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                            options={modelosInstituicao}
                                                            value={modeloInstituicao}
                                                            onChange={handleChangeModeloInstituicao}
                                                        />
                                                    </SttGrid>
                                                }
                                                {
                                                    modeloLaudo.map((modelo, idx) => (
                                                        <SttGrid item xs={12} key={idx}>
                                                            <Field name={`${CONTEUDO_TEXTUAL}.${modelo.secao}`}>
                                                                {({
                                                                    field: { value, name }, meta
                                                                }) => {
                                                                    let editorHeight = 300;
                                                                    if (modelo.secao.toLowerCase().includes(CONCLUSAO) || modelo.secao.toLowerCase().includes(DESCRICAO)) {
                                                                        editorHeight = 150;
                                                                    }
                                                                    return <>
                                                                        <SttFormLabel component="legend" className={classes.ckeditorLabel} error={(meta.touched && meta.error) ? true : false}>
                                                                            {modelo.secao}
                                                                        </SttFormLabel>
                                                                        <SttExamesCkEditor
                                                                            height={editorHeight}
                                                                            id={modelo.id}
                                                                            data={value || modelo.conteudo}
                                                                            name={name}
                                                                            onChange={(event, editor) => {
                                                                                let data = editor.getData();
                                                                                setFieldValue(name, data);
                                                                                setTextoLaudo({ ...textoLaudo, [modelo.secao.toLowerCase()]: data })
                                                                            }}
                                                                            onReady={editor => {
                                                                                atualizarEditores(editor);
                                                                            }}
                                                                            radiologia={CALCULAR_FORMULAS}
                                                                            allowPasteFromOffice
                                                                            atalhos={atalhos}
                                                                            allowTable
                                                                        />
                                                                        <SttFormHelperText error={true}>{meta.touched && meta.error ? meta.error : undefined}</SttFormHelperText>
                                                                    </>
                                                                }}
                                                            </Field>
                                                        </SttGrid>
                                                    ))
                                                }
                                            </SttGrid>
                                        }
                                    />
                                    <SttDivider className={classes.divider} />
                                    <SttExpansionPanel
                                        title={strings.descritores}
                                        compact
                                        opened={false}
                                        children={
                                            <>
                                                <SttGrid container spacing={3}>
                                                    <SttGrid item xs={12}>
                                                        <div className={classes.conteinerDecs}>
                                                            <SttCid10
                                                                strings={strings}
                                                                headers={getHeaders()}
                                                                multiplos
                                                                formExterno={{
                                                                    cid10: val.cid10,
                                                                    isSubmitting,
                                                                    errors,
                                                                    submitCount,
                                                                    setFieldValue
                                                                }}
                                                            />
                                                        </div>
                                                    </SttGrid>
                                                </SttGrid>
                                                <SttGrid container spacing={3}>
                                                    <SttGrid item xs={12}>
                                                        <div className={classes.conteinerDecs}>
                                                            <SttDecs
                                                                strings={strings}
                                                                multiplos
                                                                formExterno={{
                                                                    decs: val.decs,
                                                                    isSubmitting,
                                                                    errors,
                                                                    submitCount,
                                                                    setFieldValue
                                                                }}
                                                            />
                                                        </div>
                                                    </SttGrid>
                                                </SttGrid>
                                            </>
                                        }
                                    />
                                    <SttDivider className={classes.divider} />
                                    <SttExpansionPanel
                                        title={strings.complementoLaudo}
                                        compact
                                        opened={false}
                                        children={
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12}>
                                                    <Field name={PROFISSIONAL_DIRECIONADO}>
                                                        {({
                                                            field: { value, name, onBlur },
                                                            meta,
                                                            form: { setFieldValue }
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.medicoExecutor,
                                                                    error: meta.touched && meta.error ? meta.error : undefined
                                                                }}
                                                                onBlur={onBlur}
                                                                getOptionLabel={option => option && option.nome || ''}
                                                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                                options={executores}
                                                                value={value}
                                                                onChange={(e, item) => {
                                                                    setFieldValue(name, item || null);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                            </SttGrid>
                                        }
                                    />
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <BotaoPublicar
                                                prePublicar={calcularFormulas}
                                            />
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    calcularFormulas().then(handleSubmit);
                                                }}
                                            >
                                                {strings.resumo}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>

                                </form>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.gerandoResumo}
                                />
                                <SttAlerta
                                    open={alerta}
                                    title={tituloAlerta}
                                    message={mensagemAlerta}
                                    type={tipoAlerta}
                                    options={opcoesAlerta}
                                    onClose={onCloseAlerta || (() => setAlerta(false))}
                                />
                                {
                                    laudo &&
                                    <ModalConfirmacao
                                        html={laudo}
                                        open={confirmar}
                                        callbackCancelar={callbackCancelar}
                                    />
                                }
                            </div>
                        )
                    }
                }
            </Formik>
        </>
    );
};

FormLaudo.propTypes = {
    exame: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        laudoCopia: state.exame.laudoCopia
    };
};

export default connect(mapStateToProps)(FormLaudo);