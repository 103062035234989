import React from 'react';
import { Provider } from 'react-redux';
import { Store } from './store';
import './styles.css';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import Home from './paginas';
import { SttTranslateHook } from '@stt-componentes/core';

const App = () => {
    return (
        <div>
            <NotificationContainer />
            <Provider store={Store}>
                <SttTranslateHook.I18nContextProvider modulo={'exames'}>
                    <ThemeProvider theme={theme}>
                        <Home />
                    </ThemeProvider>
                </SttTranslateHook.I18nContextProvider>
            </Provider>
        </div>
    );
}
export default App;