import TYPES from '../types'

export const setAuthenticated = (authenticated) => {
    return {
        type: TYPES.SET_AUTHENTICATED,
        payload: { authenticated }
    };
}
export const setUser = (user) => {
    return {
        type: TYPES.SET_USER,
        payload: { user }
    };
}

export const setMessage = (message) => {
    return {
        type: TYPES.SET_MESSAGE,
        payload: { message }
    };
}