import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SttButton, SttTranslateHook } from '@stt-componentes/core';

const Oviyam = (props) => {
    const { exame, callback, token } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const location = useLocation();
    const [contextolaudo] = useState(location.pathname === '/exames/laudo');

    const handleClick = () => {
        let url = exame.url_oviyam.replace('<StudyInstanceUID>', exame.studyinstanceuid).replace('<token>', token || '');

        const oviyam = window.open(
            url,
            exame.id,
            `status=no,resizable=no,scrollbars=no,location=no,menubar=no,toolbar=no,width=${(window.screen.width * 0.8)},height=${(window.screen.height * 0.8)}`
        );

        if (callback) {
            callback(oviyam);
        }
    }

    return (
        !exame.url_oviyam ? 
        '' : 
        <SttButton
            type="button"
            variant="contained"
            color="secondary"
            onClick={handleClick}
            nomarginleft={!contextolaudo}
        >
            {strings.oviyam}
        </SttButton>
    )
}

export default Oviyam;
