import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { setParametrosPesquisa as setParametrosPesquisaAction } from '../../../../reducers/actions/exame';
import { Field } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import { temPerfilRBAC } from '../../../../secutity/acl';
import { VINCULO_PERFIL } from '../../constantes';
import { SttAutocomplete, SttTranslateHook } from '@stt-componentes/core';
import { UF, MUNICIPIO, INSTITUICAO } from '../fieldNames';
import { PERFIL } from '../../../../common/Constants';

const Uf = (props) => {
    const { user, filtros, setParametrosPesquisa } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [ufs, setUfs] = useState([]);
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

    useEffect(() => {
        let url = `${UTILITARIOS_API_BASE_URL}/localizacao/estado`;
        if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) {
            const perfil = user.perfisRBAC.filter(p => p.identificador === PERFIL.ADMINISTRADOR_ESTADUAL);
            const estados = perfil[0].estados;
            const idsUf = estados.map(e => e.id);
            url = `${url}?ids=${idsUf.join(',')}`;
        }

        axios.get(url, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    tratarFiltroUfs(itens);
                }
            })
            .catch(err => console.log(err));

    }, []);

    const tratarFiltroUfs = (estados) => {
        if (temPerfilRBAC(user, [PERFIL.MEDICO_EXECUTOR, PERFIL.MEDICO_VISUALIZADOR, PERFIL.MEDICO_RESIDENTE, PERFIL.TECNICO])) {
            const instituicoesVinculo = user.habilitacao.vinculo;
            const perfisInstituicao = user.perfisRBAC.filter(perfil => perfil.vinculo === VINCULO_PERFIL.INSTITUICAO);
            const instituicoesHabilitadas = [];
            perfisInstituicao.forEach(perfil => {
                perfil.instituicoes.forEach(instituicao => {
                    if (!instituicoesHabilitadas.some(i => i.id === instituicao.id)) {
                        instituicoesHabilitadas.push(instituicoesVinculo.find(i => i.id === instituicao.id));
                    }
                })
            });
            const listaUfs = [];
            instituicoesHabilitadas.forEach(inst => {
                if (!listaUfs.includes(inst.id_estado)) {
                    // Lista de ID's das UF's das instituições
                    listaUfs.push(inst.id_estado);
                }
            });
            // Carrega o combobox com as UF's das instituições
            const listaEstados = estados.filter(est => listaUfs.includes(est.id));
            setUfs(listaEstados);
            // Se todas são da mesma UF, seleciona e carrega os municípios
            if (listaUfs.length === 1) {
                setParametrosPesquisa({ ...filtros, uf: listaEstados[0] });
            }
            return;
        }

        if (temPerfilRBAC(user, [PERFIL.ADMINISTRADOR_MUNICIPAL])) {
            let estadosPerfil = [];
            user.perfisRBAC.forEach(p => {
                if (p.vinculo === VINCULO_PERFIL.MUNICIPAL && p.municipios && p.identificador === PERFIL.ADMINISTRADOR_MUNICIPAL) {
                    const ufs = p.municipios.map(c => c.id_estado);
                    estadosPerfil = estadosPerfil.concat(ufs);
                }
            })
            const listaUfs = [];
            estadosPerfil.forEach(ep => {
                if (!listaUfs.includes(ep)) {
                    // Lista de ID's das UF's dos perfis municipais
                    listaUfs.push(ep);
                }
            });
            const listaEstados = estados.filter(est => listaUfs.includes(est.id));
            setUfs(listaEstados);
            // Se todas são da mesma UF, seleciona e carrega os municípios
            if (listaUfs.length === 1) {
                setParametrosPesquisa({ ...filtros, uf: listaEstados[0] });
            }
            return;
        }

        if (estados.length === 1) {
            setParametrosPesquisa({ ...filtros, uf: estados[0] });
        }
        setUfs(estados);
    }

    return (
        <Field name={UF}>
            {({
                field: { name, value, onBlur },
                form: { setFieldValue }
            }) => (
                <SttAutocomplete
                    inputprops={{
                        name: name,
                        label: strings.uf
                    }}
                    getOptionLabel={option => option?.sigla || ''}
                    getOptionSelected={(option, val) => option?.id === val?.id}
                    options={ufs}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e, item) => {
                        setFieldValue(UF, item || null);
                        setFieldValue(MUNICIPIO, null);
                        setFieldValue(INSTITUICAO, null);
                    }}
                />
            )}
        </Field>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filtros: state.exame.filtros
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Uf);