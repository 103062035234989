import TYPES from '../types/exame'

export const setParametrosPesquisa = (parametros) => {
    return {
        type: TYPES.SET_FILTROS,
        payload: { parametros }
    };
}

export const reset = (parametros) => {
    return {
        type: TYPES.RESET,
        payload: { parametros }
    };
}

export const setAtualizarBusca = (atualizar) => {
    return {
        type: TYPES.ATUALIZAR_BUSCA,
        payload: { atualizar: atualizar }
    };
}

export const setLaudoCopia = (laudo) => {
    return {
        type: TYPES.COPIAR_LAUDO,
        payload: { laudoCopia: laudo }
    };
}

export const setTabs = (tabs) => {
    return {
        type: TYPES.SET_TABS,
        payload: { tabs }
    };
}

export const addTab = (tab) => {
    return {
        type: TYPES.ADD_TAB,
        payload: { tab }
    };
}

export const removeTab = (index) => {
    return {
        type: TYPES.REMOVE_TAB,
        payload: { index }
    };
}

export const setActiveTab = (index) => {
    return {
        type: TYPES.SET_ACTIVE_TAB,
        payload: { index }
    };
}

export const setUrlOhif = (dados) => {
    return {
        type: TYPES.SET_URL_OHIF,
        payload: { id: dados.id, url: dados.url }
    };
}

export const setDadosAssinaturaDigital = (dados) => {
    return {
        type: TYPES.SET_DADOS_ASSINATURA_DIGITAL,
        payload: { dados }
    };
}