import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { setParametrosPesquisa as setParametrosPesquisaAction } from '../../../../reducers/actions/exame';
import { Field, useFormikContext } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import { temPerfilRBAC } from '../../../../secutity/acl';
import { VINCULO_PERFIL } from '../../constantes';
import { SttAutocomplete, SttTranslateHook } from '@stt-componentes/core';
import { UF, MUNICIPIO, INSTITUICAO } from '../fieldNames';
import { PERFIL } from '../../../../common/Constants';

const Municipio = (props) => {
    const { user, filtros, setParametrosPesquisa } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { values } = useFormikContext();
    const [municipios, setMunicipios] = useState([]);
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

    useEffect(() => {
        if (values[UF]) {
            axios.get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${values[UF].id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        tratarFiltroMunicipio(values[UF], itens);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setMunicipios([]);
                });
        } else {
            setMunicipios([]);
        }
    }, [values[UF]]);

    const tratarFiltroMunicipio = (uf, cidades) => {
        if (temPerfilRBAC(user, [PERFIL.MEDICO_EXECUTOR, PERFIL.MEDICO_VISUALIZADOR, PERFIL.MEDICO_RESIDENTE, PERFIL.TECNICO])) {
            const instituicoesVinculo = user.habilitacao.vinculo;
            const perfisInstituicao = user.perfisRBAC.filter(perfil => perfil.vinculo === VINCULO_PERFIL.INSTITUICAO);
            const instituicoesHabilitadas = [];
            perfisInstituicao.forEach(perfil => {
                perfil.instituicoes.forEach(instituicao => {
                    if (!instituicoesHabilitadas.some(i => i.id === instituicao.id)) {
                        instituicoesHabilitadas.push(instituicoesVinculo.find(i => i.id === instituicao.id));
                    }
                })
            });
            const listaMunicipios = [];
            instituicoesHabilitadas.forEach(inst => {
                if (!listaMunicipios.includes(inst.id_cidade)) {
                    // Lista de ID's dos municípios das instituições
                    listaMunicipios.push(inst.id_cidade);
                }
            });
            // Carrega o combobox com os municípios das instituições
            const listaCidades = cidades.filter(cid => listaMunicipios.includes(cid.id));
            setMunicipios(listaCidades);
            // Se todas são no mesmo município, seleciona e carrega as instiuições
            if (listaMunicipios.length === 1) {
                setParametrosPesquisa({ ...filtros, uf: uf, municipio: listaCidades[0] });
            }
            return;
        }

        if (temPerfilRBAC(user, [PERFIL.ADMINISTRADOR_MUNICIPAL])) {
            let cidadesPerfil = [];
            user.perfisRBAC.forEach(p => {
                if (p.vinculo === VINCULO_PERFIL.MUNICIPAL && p.municipios && p.identificador === PERFIL.ADMINISTRADOR_MUNICIPAL) {
                    const municipios = p.municipios.map(c => c.id);
                    cidadesPerfil = cidadesPerfil.concat(municipios);
                }
            })
            const listaMunicipios = [];
            cidadesPerfil.forEach(cp => {
                if (!listaMunicipios.includes(cp)) {
                    // Lista de ID's das UF's dos perfis municipais
                    listaMunicipios.push(cp);
                }
            });
            const listaCidades = cidades.filter(cid => listaMunicipios.includes(cid.id));
            setMunicipios(listaCidades);
            // Se todas são no mesmo município, seleciona e carrega as instiuições
            if (listaMunicipios.length === 1) {
                setParametrosPesquisa({ ...filtros, uf: uf, municipio: listaCidades[0] });
            }
            return;
        }

        if (cidades.length === 1) {
            setParametrosPesquisa({ ...filtros, uf: uf, municipio: cidades[0] });
        }
        setMunicipios(cidades);
    }

    return (
        <Field name={MUNICIPIO}>
            {({
                field: { name, value, onBlur },
                form: { setFieldValue }
            }) => (
                <SttAutocomplete
                    inputprops={{
                        name: name,
                        label: strings.municipio
                    }}
                    getOptionLabel={option => option?.nome || ''}
                    getOptionSelected={(option, val) => option?.id === val?.id}
                    options={municipios}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e, item) => {
                        setFieldValue(MUNICIPIO, item || null);
                        setFieldValue(INSTITUICAO, null);
                    }}
                    disabled={!values[UF]}
                />
            )}
        </Field>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filtros: state.exame.filtros
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Municipio);