import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom';
import Utils from '../../utils';
import {
    SttButton,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const Download = (props) => {
    const { exame } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const location = useLocation();

    const [baixandoEstudo, setBaixandoEstudo] = useState(false);
    const [contextolaudo] = useState(location.pathname === '/exames/laudo');

    const download = (exame) => {
        setBaixandoEstudo(true);
        Utils.downloadFn(exame, () => setBaixandoEstudo(false));
    }
    
    return (
        <>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                nomarginleft={!contextolaudo}
                onClick={() => download(exame)}
            >
                {strings.download}
            </SttButton>
            <div className={classes.carregando}>
                <SttLoading open={baixandoEstudo} />
            </div>
        </>
    );
};

export default Download;
