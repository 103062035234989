export default {
    SET_FILTROS: 'SET_FILTROS',
    RESET: 'RESET',
    SET_FILTROS_LG: 'SET_FILTROS_LG',
    RESET_LG: 'RESET_LG',
    ATUALIZAR_BUSCA: 'ATUALIZAR_BUSCA',
    COPIAR_LAUDO: 'COPIAR_LAUDO',
    SET_TABS: 'SET_TABS',
    ADD_TAB: 'ADD_TAB',
    REMOVE_TAB: 'REMOVE_TAB',
    SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
    SET_URL_OHIF: 'SET_URL_OHIF',
    SET_DADOS_ASSINATURA_DIGITAL: 'SET_DADOS_ASSINATURA_DIGITAL'
};