import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Laudo from 'src/componentes/laudo';
import axios from 'axios';
import { getHeaders } from '../../request';
import { makeStyles } from '@material-ui/core';
import { SttTabs, SttLoading, SttTranslateHook, SttAlerta } from '@stt-componentes/core';
import { v4 as uuidv4 } from 'uuid';
import AbaVisualizacao from '../../componentes/exame/visualizacao';
import moment from 'moment';
import { setLaudoCopia as setLaudoCopiaAction, setUrlOhif } from '../../reducers/actions/exame';
import { temPermissaoRBAC } from '../../secutity/acl';
import { PERMISSOES } from '../../common/Constants';


const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const ConteinerLaudo = (props) => {
    const { user, setLaudoCopia, setUrlOhif } = props;
    const classes = useStyles();
    const location = useLocation();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [abas, setAbas] = useState([]);
    const [abaAtiva, setAbaAtiva] = useState('0');
    const [idExame, setIdExame] = useState(null);
    const [historicoCarregado, setHistoricoCarregado] = useState(false);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState(null);
    const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;
    
    /**
     * Obtém o id do exame
     */
    useEffect(() => {
        const id = location.state?.exame;
        setIdExame(id);

        return () => {
            setUrlOhif(id, null);
        }
    }, [location]);

    useEffect(() => {
        // Carrega histórico de exames do paciente
        if (idExame) {
            setAbas([{
                titulo: strings.exameAtual,
                conteudo: Laudo,
                dados: { idExame },
                key: uuidv4()
            }]);

            setLoading(false);
            
        }
    }, [idExame]);
    
    useEffect(() => {
        if (abas.length > 0 && !historicoCarregado && temPermissaoRBAC(user, PERMISSOES.ACESSAR_HISTORICO_EXAMES)) {
            pesquisarHistoricoExames();
        }
    }, [abas]);

    const pesquisarHistoricoExames = () => {
        axios
            .get(`${EXAMES_API_BASE_URL}/laudo/historico/${idExame}`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;
                if (data?.length > 0) {
                    criarAbas(data);
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.erroGenerico;
                let arrMensagem = [];
                if (response) { 
                    const { data } = response;
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    msg = arrMensagem.join('\n');
                }
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: () =>  setErro(false)
                }]);
                setMensagemErro(msg);
                setErro(true);
            })
            .finally(() => setHistoricoCarregado(true));
    }

    const carregarLaudoExameHistorico = (idExameHistorico) => {
        setLoadingMessage(strings.copiandoLaudo);
        setLoading(true);
        axios
            .get(`${EXAMES_API_BASE_URL}/laudo/copia/${idExameHistorico}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    let { data } = response.data;
                    if (data) {
                        setLaudoCopia(data);
                    }
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.erroDesconhecido;
                let arrMensagem = [];
                if (response) {
                    const { data } = response;
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    msg = arrMensagem.join('\n');
                }
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: () => setErro(false)
                }]);
                setMensagemErro(msg);
                setErro(true);
            }).finally(() => {
                setLoading(false);
            });
    }

    const criarAbas = (historico) => {
        const abasHistorico = [];
        historico.forEach((exameHistorico) => {
            let titulo = '';
            if (exameHistorico.data) {
                titulo += `Data do exame: ${moment(exameHistorico.data).format('DD/MM/YYYY')}`;
            }
            if (exameHistorico.requisicao) {
                titulo += `\nRequisição: ${exameHistorico.requisicao}`;
            }

            abasHistorico.push({
                titulo: titulo,
                conteudo: AbaVisualizacao,
                id: exameHistorico.id,
                contextoLaudo: true,
                copiarLaudo: carregarLaudoExameHistorico,
                key: uuidv4()
            });
        });
        setAbas([ ...abas, ...abasHistorico ]);
    }
    
    return (
        <>
            <div className={classes.carregando}>
                <SttLoading open={loading} text={loadingMessage} />
            </div>
            <SttTabs 
                abas={abas} 
                handleChangeAbaAtiva={setAbaAtiva}
                abaAtiva={abaAtiva}
                permanente={true}
                preload={false}
            />
            <SttAlerta
                open={erro}
                title={strings.erro}
                message={mensagemErro}
                type="error"
                options={opcoesAlerta}
                onClose={() => setErro(false)}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLaudoCopia: laudo => dispatch(setLaudoCopiaAction(laudo)),
        setUrlOhif: (id, url) => dispatch(setUrlOhif({ id, url }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConteinerLaudo);
