import React, { useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from '../request';
import axios from 'axios';
import { connect } from 'react-redux';
import { PERFIL, PERMISSOES } from '../common/Constants';
import { setAuthenticated as setAuthenticatedAction, setUser as setUserAction } from '../reducers/actions';
import { setConfig as setConfigAssinaturaDigital} from '../reducers/actions/assinatura-digital';
import { Switch, Route } from 'react-router-dom';
import Menu from '../componentes/menu';
import Exames from './exame';
import Visualizacao from './exame/visualizacao';
import Erro401 from './erro/401';
import { SttCircularProgress, SttCacheManager, SttTranslateHook } from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';
import ProtectedRoute from './protectedRoute';
import Laudo from './laudo';
import Administrativo from './administrativo';
import Produtividade from './produtividade';
import { temPerfilRBAC } from 'src/secutity/acl';
import { NotificationManager } from 'react-notifications';

const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Interno = ({ user, isAuthenticated, setAuthenticated, setUser, setConfigAssinaturaDigital }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    useEffect(() => {
        if (user && temPerfilRBAC(user, PERFIL.MEDICO_EXECUTOR)) {
            const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;
            axios
                .get(
                    `${EXAMES_API_BASE_URL}/laudo/assinatura-digital`,
                    { headers: getHeaders() }
                )
                .then((response) => {
                    if (response) {
                        setConfigAssinaturaDigital(response.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                    NotificationManager.error(strings.erroCarregarParametrosAssinaturaDigital);
                });
        }
    }, [user]);

    return (
        <div>
            {
                isAuthenticated
                    ?
                    <div className={classes.wrapper}>
                        <Menu />
                        <Switch>
                            <Route path="/nao-autorizado" exact component={Erro401} />
                            <Route path={["/", "/inicio"]} exact children={<ProtectedRoute component={Produtividade} permissao={[PERMISSOES.CADASTRAR_MODELO_LAUDO, PERMISSOES.EXCLUIR_MODELO_LAUDO]} />} />
                            <Route path="/administrativo" exact children={<ProtectedRoute component={Administrativo} permissao={[PERMISSOES.CADASTRAR_MODELO_LAUDO, PERMISSOES.EXCLUIR_MODELO_LAUDO]} />} />
                            <Route path="/exames" exact children={<ProtectedRoute component={Exames} permissao={PERMISSOES.VISUALIZAR_EXAME} />} />
                            <Route path="/exames/laudo" exact children={<ProtectedRoute component={Laudo} perfil={[PERFIL.MEDICO_EXECUTOR, PERFIL.MEDICO_RESIDENTE]} />} />
                            <Route path="/exames/exame" exact children={<ProtectedRoute component={Visualizacao} permissao={PERMISSOES.VISUALIZAR_EXAME} />} />
                        </Switch>
                    </div>
                    :
                    <>
                        <SttCacheManager config={global.gConfig} onSync={loaded => {
                            const user = Cache.getUserInfo();
                            setUser(user);
                            setAuthenticated(loaded);
                        }} hasExternalToken={true} />
                        <div className={classes.loading}>
                            <SttCircularProgress />
                        </div>
                    </>
            }
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        isAuthenticated: state.index.isAuthenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthenticated: (authenticated) => dispatch(setAuthenticatedAction(authenticated)),
        setUser: (user) => dispatch(setUserAction(user)),
        setConfigAssinaturaDigital: (config) => dispatch(setConfigAssinaturaDigital(config))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interno);
