import React, { useState, useEffect } from 'react'
import { SttGrid } from '@stt-componentes/core';
import axios from 'axios';
import { getHeaders } from 'src/request';
import Modalidade from './modalidade';

const Inicio = (props) => {
    const { id } = props;
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
    const [dados, setDados] = useState([]);

    useEffect(() => {
        axios
            .get(`${EXAME_API_BASE_URL}/produtividade/modalidade-ano`, {
                headers: getHeaders()
            })
            .then((response) => {
                setDados(response.data);
            })
            .catch(err => console.log(err));
    }, []);


    return (
        <SttGrid container spacing={10}>
            {dados.map((item, index) => (
                <SttGrid item xs={12} sm={6} md={4} key={index}>
                    <Modalidade
                        titulo={item.modalidade}
                        modalidade={item.id_modalidade}
                        instituicao={id}
                    />
                </SttGrid>
            ))}
        </SttGrid>
    );
}

export default Inicio;