import React, { useContext } from 'react';
import { FastField } from 'formik';
import { SttTranslateHook, SttNumberInput } from '@stt-componentes/core';
import { NUMERO_EXAME } from '../fieldNames';

const NumeroExame = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    
    return (
        <FastField name={NUMERO_EXAME}>
            {({
                field
            }) => (
                <SttNumberInput
                    label={strings.numeroLaudo}
                    inputProps={{
                        inputMode: 'numeric'
                    }}
                    {...field}
                />
            )}
        </FastField>
    );
};

export default NumeroExame;