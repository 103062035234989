import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AbaVisualizacao from '../../componentes/exame/visualizacao';
import { SttTabs } from '@stt-componentes/core';

const Visualizacao = () => {

    const location = useLocation();
    const [abas, setAbas] = useState([]);
    
    useEffect(() => {
        const id = location.state?.exame;
        const instituicao = location.state?.instituicao;
        setAbas([{
            titulo: instituicao.nome,
            conteudo: AbaVisualizacao,
            key: instituicao.id,
            permanente: true,
            id: id
        }]);
    }, [location]);

    return (
        <SttTabs 
            abas={abas}
            abaAtiva="0"
            permanente={true}
            preload={false}
        />
    );

}

export default Visualizacao;