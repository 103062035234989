import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { FastField } from 'formik';
import {
    SttGrid,
    SttInput,
    SttTranslateHook,
    SttCardHeader,
    SttCardContent,
    SttCard,
    SttMaskedInput
} from '@stt-componentes/core';
import { NOME_PACIENTE, CPF_PACIENTE, CNS_PACIENTE } from '../fieldNames';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%'
    }
}));

const Paciente = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <SttCard variant="outlined" className={classes.card}>
            <SttCardHeader subheader={strings.paciente} />
            <SttCardContent>
                <SttGrid container spacing={1}>
                    <SttGrid item xs={12} sm={12} md={6}>
                        <FastField name={NOME_PACIENTE}>
                            {({
                                field
                            }) => (
                                <SttInput
                                    {...field}
                                    label={strings.nome}
                                />
                            )}
                        </FastField>
                    </SttGrid>
                    <SttGrid item xs={12} sm={6} md>
                        <FastField name={CPF_PACIENTE}>
                            {({
                                field,
                                meta
                            }) => (
                                <SttMaskedInput
                                    {...field}
                                    inputMode = 'numeric'
                                    mask="cpf"
                                    label={strings.cpf}
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                />
                            )}
                        </FastField>
                    </SttGrid>
                    <SttGrid item xs={12} sm={6} md>
                        <FastField name={CNS_PACIENTE}>
                            {({
                                field,
                                meta,
                            }) => (
                                <SttMaskedInput
                                    {...field}
                                    inputMode = 'numeric'
                                    mask="cns"
                                    label={strings.cns}
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                />
                            )}
                        </FastField>
                    </SttGrid>
                </SttGrid>
            </SttCardContent>
        </SttCard>
    );
};

export default Paciente;