import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../request';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DescriptionIcon from '@material-ui/icons/Description';
import ExameImagemIcon from './imagens/exame-imagem.png';
import Download from './download';
import { PERFIL, PERMISSOES, MODALIDADES_TELEDIAGNOSTICO, SIM } from '../../common/Constants';
import { temPerfilRBAC, temPermissaoRBAC } from '../../secutity/acl';
import Utils from '../../utils';
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTablePagination,
    SttCircularProgress,
    SttMenu,
    SttMenuItem,
    SttListItemIcon,
    SttListItemText,
    SttGrid,
    SttAlerta,
    SttLoading,
    SttTranslateHook,
    SttHidden,
    SttText
} from '@stt-componentes/core';
import { Chip, IconButton } from '@material-ui/core';
import { LAUDO_EM_EMISSAO, SITUACAO, SITUACAO_LAUDO } from './constantes';
import moment from 'moment';
import { ListaExames } from '@stt-utilitarios/core';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}));

const AbaHistoricoPaciente = (props) => {
    const { user, idPaciente, origemPaciente } = props;
    const classes = useStyles();
    const history = useHistory();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [exames, setExames] = useState([]);
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(true);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [colspan, setColspan] = useState(8);
    const [menuOptions, setMenuOption] = useState([]);
    const [baixandoEstudo, setBaixandoEstudo] = useState(false);
    const [erro, setErro] = useState(false);

    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    const downloadEstudoDicom = (exame) => {
        setBaixandoEstudo(true);
        Utils.downloadFn(exame, () => setBaixandoEstudo(false));
    }
    useEffect(() => {
        if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) {
            setColspan(10);
        } else if (temPerfilRBAC(user, 'tecnico') ||
            temPerfilRBAC(user, 'medicoLaudador') ||
            temPerfilRBAC(user, 'medicoExecutor')) {
            setColspan(9)
        }

        let menu = [];
        if (temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_EXAME) || temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_LAUDO)) {
            menu.push({
                showFunction: (exame) => {
                    return [
                        SITUACAO_LAUDO.COM_LAUDO,
                        SITUACAO_LAUDO.SEM_LAUDO,
                        SITUACAO_LAUDO.LAUDO_TEMPORARIO,
                        SITUACAO_LAUDO.LAUDO_EMISSAO,
                        SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO
                    ].includes(exame.situacao_laudo);

                },
                icon: VisibilityIcon,
                title: strings.ver,
                createOnClick: (exame) => {
                    history.push('/exames/exame', { exame: exame.id, instituicao: { id: exame.id_instituicao, nome: exame.instituicao } });
                }
            });
        }
        if (temPermissaoRBAC(user, PERMISSOES.DOWNLOAD_EXAME)) {
            menu.push({
                showFunction: (exame) => {
                    return [
                        SITUACAO_LAUDO.COM_LAUDO,
                        SITUACAO_LAUDO.SEM_LAUDO,
                        SITUACAO_LAUDO.LAUDO_TEMPORARIO,
                        SITUACAO_LAUDO.LAUDO_EMISSAO,
                        SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO
                    ].includes(exame.situacao_laudo) &&
                        !MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade) &&
                        exame.possui_imagem === SIM;
                },
                icon: CloudDownloadIcon,
                title: strings.download,
                createOnClick: downloadEstudoDicom,
            });
        }
        if (temPerfilRBAC(user, PERFIL.MEDICO_EXECUTOR) || temPerfilRBAC(user, PERFIL.MEDICO_RESIDENTE)) {
            menu.push({
                showFunction: (exame) => {
                    const valido = exame.situacao === SITUACAO.VALIDO;
                    const situacaoLaudo = [
                        SITUACAO_LAUDO.SEM_LAUDO,
                        SITUACAO_LAUDO.LAUDO_TEMPORARIO,
                        SITUACAO_LAUDO.LAUDO_AGUARDANDO_COMPLEMENTO
                    ].includes(exame.situacao_laudo);
                    const modalidade = !MODALIDADES_TELEDIAGNOSTICO.includes(exame.sigla_modalidade);
                    const laudador = exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO && exame.id_funcionario_laudador === user.idFuncionario;

                    const emitindoLaudo = exame.laudo_em_emissao === LAUDO_EM_EMISSAO.SIM &&
                        exame.id_funcionario_emissao === user.idFuncionario;

                    return valido && modalidade && (situacaoLaudo || laudador || emitindoLaudo);
                },
                icon: DescriptionIcon,
                title: strings.laudo,
                createOnClick: (exame) => {
                    history.push(
                        '/exames/laudo',
                        {
                            exame: exame.id,
                            instituicao: {
                                id: exame.id_instituicao,
                                nome: exame.instituicao
                            }
                        });
                },
            });
        }
        setMenuOption(menu);
    }, []);

    useEffect(() => {
        if (buscaEmAndamento) {
            const params = {
                'id-paciente': idPaciente,
                'origem-paciente': origemPaciente,
                start: page * count,
                count
            };
            axios
                .get(`${EXAME_API_BASE_URL}/exames`, { params: params, headers: getHeaders() })
                .then((response) => {
                    const dados = response.data.data;
                    setTotalRegistros(parseInt(dados.totalRegistros));
                    setExames(dados.itens);
                    setBuscaEmAndamento(false);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [buscaEmAndamento]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscaEmAndamento(true);
    };

    const handleChangeRowsPerPage = event => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.page);
        setBuscaEmAndamento(true);
    };

    return (
        <>
            <SttGrid container spacing={1}>
                <SttGrid item xs={12}>
                    <div className={classes.tableWrapper}>
                        <SttTable>
                            <SttTableHead>
                                <SttTableRow>
                                    <SttHidden smDown>
                                        <SttTableCell>
                                            {strings.dataExame}
                                        </SttTableCell>
                                        {
                                            (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) &&
                                            <>
                                                <SttTableCell>
                                                    {`${strings.municipio}/${strings.uf}`}
                                                </SttTableCell>
                                                <SttTableCell>
                                                    {strings.instituicao}
                                                </SttTableCell>
                                            </>
                                        }
                                        {
                                            (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
                                                temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) ||
                                                temPerfilRBAC(user, PERFIL.ADMINISTRADOR_TELEMEDICINA) ||
                                                temPerfilRBAC(user, PERFIL.ADMINISTRADOR_LOCAL) ||
                                                temPerfilRBAC(user, PERFIL.TECNICO) ||
                                                temPerfilRBAC(user, PERFIL.MEDICO_LAUDADOR)) &&
                                            <SttTableCell>
                                                {strings.tecnico}
                                            </SttTableCell>
                                        }
                                        <SttTableCell>
                                            {strings.requisicao}
                                        </SttTableCell>
                                        <SttTableCell>
                                            {strings.paciente}
                                        </SttTableCell>
                                        <SttTableCell>
                                            {strings.descricaoExame}
                                        </SttTableCell>
                                        <SttTableCell>
                                            {strings.situacao}
                                        </SttTableCell>
                                        <SttTableCell align='center'>
                                            {strings.imagem}
                                        </SttTableCell>
                                        <SttTableCell align="center">
                                            {strings.opcoes}
                                        </SttTableCell>
                                    </SttHidden>
                                </SttTableRow>
                                <SttTableRow>
                                    <SttHidden mdUp>
                                        <SttTableCell width='100%'>
                                            {strings.dadosExame}
                                        </SttTableCell>
                                        <SttTableCell align='center'>{strings.opcoes}</SttTableCell>
                                    </SttHidden>
                                </SttTableRow>
                            </SttTableHead>
                            <SttTableBody>
                                {
                                    buscaEmAndamento
                                        ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={colspan} align="center">
                                                <SttCircularProgress />
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        (
                                            exames.length === 0 ?
                                                <SttTableRow key={-1}>
                                                    <SttTableCell colSpan={colspan} align="center">
                                                        {strings.nenhumRegistroEncontrado}
                                                    </SttTableCell>
                                                </SttTableRow>
                                                :
                                                exames.map((row, index) => (
                                                    <SttTableRow key={index}>
                                                        <SttHidden smDown>
                                                            <SttTableCell>{`${moment(row.data_exame).format('DD/MM/YYYY HH:mm:ss')}`}</SttTableCell>
                                                            {
                                                                (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) &&
                                                                <>
                                                                    <SttTableCell>{row.municipio} / {row.uf}</SttTableCell>
                                                                    <SttTableCell>{row.instituicao}</SttTableCell>
                                                                </>
                                                            }
                                                            {
                                                                (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
                                                                    temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) ||
                                                                    temPerfilRBAC(user, PERFIL.TECNICO) ||
                                                                    temPerfilRBAC(user, PERFIL.MEDICO_LAUDADOR)) &&
                                                                <SttTableCell>{row.funcionario_envio}</SttTableCell>

                                                            }
                                                            <SttTableCell>{row.requisicao}</SttTableCell>
                                                            <SttTableCell>{row.paciente}</SttTableCell>
                                                            <SttTableCell>{row.descricao_exame}</SttTableCell>
                                                            <SttTableCell>
                                                                <Chip
                                                                    style={{ backgroundColor: ListaExames.getCorLegenda(row) }}
                                                                    label={ListaExames.getTextoLegenda(row, strings)}
                                                                    size="small"
                                                                />
                                                            </SttTableCell>
                                                            <SttTableCell align='center'>
                                                                {row.possui_imagem === SIM && (
                                                                    <img src={ExameImagemIcon} width={36} />
                                                                )}
                                                            </SttTableCell>
                                                            <SttTableCell align="center">
                                                                {
                                                                    menuOptions.length > 0 &&
                                                                    <PopupState variant="popover">
                                                                        {(popupState) => (
                                                                            <>
                                                                                <IconButton aria-haspopup="true" {...bindTrigger(popupState)}>
                                                                                    <MoreVertIcon />
                                                                                </IconButton>
                                                                                <SttMenu {...bindMenu(popupState)}>
                                                                                    {menuOptions.map((item, idx) => {
                                                                                        if (item.showFunction(row)) {
                                                                                            return (
                                                                                                <SttMenuItem
                                                                                                    key={idx}
                                                                                                    onClick={() => {
                                                                                                        popupState.close();
                                                                                                        item.createOnClick(row);
                                                                                                    }}
                                                                                                >
                                                                                                    <SttListItemIcon>
                                                                                                        <item.icon />
                                                                                                    </SttListItemIcon>
                                                                                                    <SttListItemText primary={item.title} />
                                                                                                </SttMenuItem>
                                                                                            );
                                                                                        }
                                                                                    })}
                                                                                </SttMenu>
                                                                            </>
                                                                        )}
                                                                    </PopupState>
                                                                }
                                                            </SttTableCell>
                                                        </SttHidden>
                                                        <SttHidden mdUp>
                                                            <SttTableCell>
                                                                <SttText size='small'>
                                                                    <b>{strings.data}: </b>{`${moment(row.data_exame).format('DD/MM/YYYY HH:mm')}`}
                                                                </SttText>
                                                                {(temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
                                                                    temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) && (
                                                                        <>
                                                                            <SttText size='small'>
                                                                                <b>{`${strings.municipio}/${strings.uf}`}: </b>
                                                                                {row.municipio}
                                                                            </SttText>
                                                                        </>
                                                                    )}
                                                                <SttText size='small'>
                                                                    <b>{strings.instituicao}: </b> {row.instituicao}
                                                                </SttText>
                                                                {row.funcionario_envio && (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) ||
                                                                    temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL) ||
                                                                    temPerfilRBAC(user, PERFIL.ADMINISTRADOR_TELEMEDICINA) ||
                                                                    temPerfilRBAC(user, PERFIL.ADMINISTRADOR_LOCAL) ||
                                                                    temPerfilRBAC(user, PERFIL.TECNICO) ||
                                                                    temPerfilRBAC(user, PERFIL.MEDICO_LAUDADOR)) && (
                                                                        <SttText size='small'>
                                                                            <b>{strings.tecnico}: </b>
                                                                            {row.funcionario_envio}
                                                                        </SttText>
                                                                    )}
                                                                <SttText size='small'>
                                                                    <b>{strings.requisicao}: </b> {row.requisicao}
                                                                </SttText>
                                                                <SttText size='small'>
                                                                    <b>{strings.paciente}: </b> {row.paciente}
                                                                </SttText>
                                                                <SttText size='small'>
                                                                    <b>{strings.descricaoExame}: </b>
                                                                    {row.descricao_exame}
                                                                </SttText>
                                                                <SttText size='small'>
                                                                    <b>{strings.situacao}:  </b>
                                                                    <Chip
                                                                        style={{
                                                                            backgroundColor: ListaExames.getCorLegenda(row),
                                                                        }}
                                                                        label={ListaExames.getTextoLegenda(row, strings)}
                                                                        size='small'
                                                                    />
                                                                </SttText>
                                                                <SttText size='small'>
                                                                    {row.possui_imagem === SIM && (
                                                                        <b
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            {strings.imagem}:
                                                                            <img
                                                                                src={ExameImagemIcon}
                                                                                width={25}
                                                                                style={{ marginLeft: '5px' }}
                                                                            />
                                                                        </b>
                                                                    )}
                                                                </SttText>
                                                            </SttTableCell>
                                                            <SttTableCell align='center'>
                                                                {menuOptions.length > 0 && (
                                                                    <PopupState variant='popover'>
                                                                        {(popupState) => (
                                                                            <>
                                                                                <IconButton
                                                                                    aria-haspopup='true'
                                                                                    {...bindTrigger(popupState)}
                                                                                >
                                                                                    <MoreVertIcon />
                                                                                </IconButton>
                                                                                <SttMenu {...bindMenu(popupState)}>
                                                                                    {menuOptions.map((item, idx) => {
                                                                                        if (item.showFunction(row)) {
                                                                                            return (
                                                                                                <SttMenuItem
                                                                                                    key={idx}
                                                                                                    onClick={() => {
                                                                                                        popupState.close();
                                                                                                        item.createOnClick(row);
                                                                                                    }}
                                                                                                >
                                                                                                    <SttListItemIcon>
                                                                                                        <item.icon />
                                                                                                    </SttListItemIcon>
                                                                                                    <SttListItemText
                                                                                                        primary={item.title}
                                                                                                    />
                                                                                                </SttMenuItem>
                                                                                            );
                                                                                        }
                                                                                    })}
                                                                                </SttMenu>
                                                                            </>
                                                                        )}
                                                                    </PopupState>
                                                                )}
                                                            </SttTableCell>
                                                        </SttHidden>
                                                    </SttTableRow>
                                                ))
                                        )
                                }
                            </SttTableBody>
                        </SttTable>
                    </div>
                </SttGrid>
            </SttGrid>
            {
                exames.length > 0 ?
                    <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                        component="div"
                        count={totalRegistros}
                        rowsPerPage={count}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={strings.linhasPorPagina}
                        labelDisplayedRows={(params) => `${params.from}-${params.to} ${strings.de} ${params.count}`}
                    />
                    : null
            }

            <div className={classes.carregando}>
                <SttLoading
                    open={baixandoEstudo}
                    text={strings.baixandoExame}
                />
            </div>
            <SttAlerta
                open={erro}
                title={strings.erro}
                message={strings.erroDownloaEstudoDicom}
                type="error"
                options={[{ title: strings.ok, onClick: () => setErro(false) }]}
                onClose={() => setErro(false)}
            />
        </>
    );
};

AbaHistoricoPaciente.propTypes = {
    strings: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(AbaHistoricoPaciente);