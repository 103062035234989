import React, { useContext } from 'react';
import { Field } from 'formik';
import { SttFormControl, SttFormGroup, SttFormControlLabel, SttCheckbox, SttTranslateHook } from '@stt-componentes/core';
import { EXAMES_PRIORIZADOS } from '../fieldNames';

const ExamePriorizado = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    return (
        <Field name={EXAMES_PRIORIZADOS}>
            {({
                field: { checked, name, ...other},
                form
            }) => (
                <SttFormControl variant="outlined">
                    <SttFormGroup row>
                        <SttFormControlLabel
                            control={
                                <SttCheckbox
                                    {...other}
                                    namne={name}
                                    color="primary"
                                    checked={checked || form.values[EXAMES_PRIORIZADOS]}
                                    onChange={e => form.setFieldValue(name, e.target.checked)}
                                />
                            }
                            label={strings.examePriorizado}
                        />
                    </SttFormGroup>
                </SttFormControl>
            )}
        </Field>
    );
};

export default ExamePriorizado;