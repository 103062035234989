import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { getHeaders } from '../../request';
import { useLocation } from 'react-router-dom';
import { ACESSO_WEASIS } from '../../common/Constants';
import { SttButton, SttAlerta, SttCircularProgress, SttTranslateHook } from '@stt-componentes/core';

const Weasis = (props) => {
    const { user, exame, token } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [contextolaudo] = useState(location.pathname === '/exames/laudo');

    const handleClick = () => {
        // Se download a cada exame
        if (user.acessoWeasis === ACESSO_WEASIS.DOWNLOAD) {
            setLoading(true);
            const progress = (progressEvent) => {
                let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100);
                setLoadingMessage(`${percentCompleted}% `);
            }
            const urlDownloadWeasis = `${global.gConfig.url_base_exames}/exame/${Buffer.from(exame.id.toString()).toString('base64')}/weasis`;
            axios
                .get(urlDownloadWeasis, { headers: getHeaders(), responseType: 'blob', onDownloadProgress: progress })
                .then((response) => {
                    if (response.data) {
                        const blob = new Blob([response.data], { type: "application/zip" });
                        saveAs(blob, `Estudo_${exame.nome_paciente.replace(/\W+|\s+/g, '_')}.zip`);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setOpcoesAlerta([{
                        title: strings.ok,
                        onClick: () => setErro(false)
                    }]);
                    setMensagemErro(strings.erroDownloadWeasis);
                    setErro(true);
                })
                .finally(() => {
                    setLoading(false);
                    setLoadingMessage('');
                });

        } else {
            // Se weasis instalado
            let url = `${global.gConfig.url_base_weasis}?studyUID=${exame.studyinstanceuid}`;
            if (exame.url_base_pacs.includes('dcm4chee-arc')) {
                url = `${url}&access_token=${token}`;
            }
            window.open(
                url,
                `${exame.id}_weasis`,
                `status=no,resizable=no,scrollbars=no,location=no,menubar=no,toolbar=no,width=${(window.screen.width * 0.8)},height=${(window.screen.height * 0.8)}`
            );
        }
    }

    return (
        <>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                nomarginleft={!contextolaudo}
                startIcon={loading && <SttCircularProgress style={{ width: '20px', height: '20px' }} />}
                onClick={handleClick}
            >
                {`${loadingMessage}${strings.weasis}`}
            </SttButton>

            <SttAlerta
                open={erro}
                title={strings.erro}
                message={mensagemErro}
                type="error"
                options={opcoesAlerta}
                onClose={() => setErro(false)}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps)(Weasis);
