import React, { useContext } from 'react';
import { FastField } from 'formik';
import { SttInput, SttTranslateHook } from '@stt-componentes/core';
import { REQUISICAO } from '../fieldNames';

const Requisicao = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    return (
        <FastField name={REQUISICAO}>
            {({
                field
            }) => (
                <SttInput
                    {...field}
                    label={strings.requisicao}
                />
            )}
        </FastField>
    );
};

export default Requisicao;