import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useFormikContext } from 'formik';
import {
    SttGrid,
    SttTranslateHook,
    SttCardHeader,
    SttCardContent,
    SttCard,
    SttCollapse
} from '@stt-componentes/core';
import DataInicialExame from './campos/data-inicial-exame';
import DataFinalExame from './campos/data-final-exame';
import DataInicialLaudo from './campos/data-inicial-laudo';
import DataFinalLaudo from './campos/data-final-laudo';
import SituacaoLaudo from './campos/situacao-laudo';
import SituacaoExame from './campos/situacao-exame';
import Origem from './campos/origem';
import Uf from './campos/uf';
import Municipio from './campos/municipio';
import Instituicao from './campos/instituicao';
import Requisicao from './campos/requisicao';
import Protocolo from './campos/protocolo';
import NumeroExame from './campos/numero-exame';
import Modalidade from './campos/modalidade';
import DescricaoExame from './campos/descricao-exame';
import ExameDirecionado from './campos/exame-direcionado';
import LaudadoPorMim from './campos/laudado-por-mim';
import ExameValidado from './campos/exame-validado';
import ExamePriorizado from './campos/exame-priorizado';
import Paciente from './campos/paciente';
import ComImagem from './campos/com-imagem';
import { 
    DATA_LAUDO_DE,
    DATA_LAUDO_ATE,
    UF,
    MUNICIPIO,
    REQUISICAO,
    PROTOCOLO,
    NUMERO_EXAME,
    DESCRICAO_EXAME,
    EXAMES_DIRECIONADOS,
    LAUDADO_POR_MIM,
    EXAMES_VALIDADOS,
    EXAMES_PRIORIZADOS,
    SOMENTE_COM_IMAGENS
} from './fieldNames';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%'
    }
}));

const FormExecutor = (props) => {
    const { pesquisaAvancada } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { setFieldValue } = useFormikContext();
    const classes = useStyles();

    useEffect(() => {
        if (!pesquisaAvancada) {
            setFieldValue(DATA_LAUDO_DE, null);
            setFieldValue(DATA_LAUDO_ATE, null);
            setFieldValue(UF, null);
            setFieldValue(MUNICIPIO, null);
            setFieldValue(REQUISICAO, '');
            setFieldValue(PROTOCOLO, '');
            setFieldValue(NUMERO_EXAME, '');
            setFieldValue(DESCRICAO_EXAME, '');
            setFieldValue(EXAMES_DIRECIONADOS, false);
            setFieldValue(LAUDADO_POR_MIM, false);
            setFieldValue(EXAMES_VALIDADOS, false);
            setFieldValue(EXAMES_PRIORIZADOS, false);
            setFieldValue(SOMENTE_COM_IMAGENS, false);
        }
    }, [pesquisaAvancada]);

    return (
        <>
            <SttGrid container spacing={2}>
                <SttGrid item xs={12}>
                    <SttCard variant="outlined" className={classes.card}>
                        <SttCardHeader subheader={strings.exame} />
                        <SttCardContent>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} sm={4} md={3}>
                                    <DataInicialExame />
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={3}>
                                    <DataFinalExame />
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={3}>
                                    <Modalidade />
                                </SttGrid>
                                <SttGrid item xs={12} sm={12} md={3}>
                                    <Instituicao />
                                </SttGrid>
                            </SttGrid>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} lg={9} xl={7}>
                                    <SituacaoLaudo />
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={3} lg={3} xl={2}>
                                    <SituacaoExame />
                                </SttGrid>
                                <SttGrid item xs={12} sm={8} xl={3}>
                                    <Origem />
                                </SttGrid>
                            </SttGrid>
                        </SttCardContent>
                    </SttCard>
                </SttGrid>
                <SttGrid item xs={12}>
                    <Paciente />
                </SttGrid>
                <SttGrid item xs={12}>
                    <SttCollapse in={pesquisaAvancada}>
                        <SttCard variant="outlined" className={classes.card}>
                            <SttCardHeader subheader={strings.pesquisaAvancada} />
                            <SttCardContent>
                                <SttGrid container spacing={1}>
                                    <SttGrid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                        <DataInicialLaudo />
                                    </SttGrid>
                                    <SttGrid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                        <DataFinalLaudo />
                                    </SttGrid>
                                    <SttGrid item xs={12} sm={3} md={3} lg={1}>
                                        <Uf />
                                    </SttGrid>
                                    <SttGrid item xs={12} sm={4} md={4} lg={3}>
                                        <Municipio />
                                    </SttGrid>
                                    <SttGrid item xs={12} sm={4} md={2}>
                                        <Requisicao />
                                    </SttGrid>
                                    <SttGrid item xs={12} sm={4} md={2}>
                                        <Protocolo />
                                    </SttGrid>
                                    <SttGrid item xs={12} sm={4} md={2}>
                                        <NumeroExame />
                                    </SttGrid>
                                    
                                    <SttGrid item xs={12} sm={4} md={2}>
                                        <DescricaoExame />
                                    </SttGrid>
                                    <SttGrid item xs={12}>
                                        <ExameDirecionado />
                                        <LaudadoPorMim />
                                        <ExameValidado />
                                        <ExamePriorizado />
                                        <ComImagem />
                                    </SttGrid>
                                </SttGrid>                                    
                            </SttCardContent>
                        </SttCard>
                    </SttCollapse>
                </SttGrid>
            </SttGrid>
        </>
    );
};

export default FormExecutor;