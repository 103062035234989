import React, { useState, useEffect, useContext } from 'react';
import { Field } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import { SttAutocomplete, SttTranslateHook } from '@stt-componentes/core';
import { REDE } from '../fieldNames';

const Rede = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    
    const [redes, setRedes] = useState([]);
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

    useEffect(() => {
        axios.get(`${UTILITARIOS_API_BASE_URL}/rede`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setRedes(itens);
                }
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <Field name={REDE}>
            {({
                field: { name, value, onBlur },
                form: { setFieldValue }
            }) => (
                <SttAutocomplete
                    inputprops={{
                        name: name,
                        label: strings.rede
                    }}
                    getOptionLabel={option => option && option.descricao || ''}
                    getOptionSelected={(option, val) => option && val && option.id === val.id}
                    options={redes}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e, item) => {
                        setFieldValue(REDE, item || null);
                    }}
                />
            )}
        </Field>
    );
};

export default Rede;