import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setTabs, addTab, removeTab, setActiveTab } from '../../reducers/actions/exame';
import AbaPesquisa from '../../componentes/exame/aba';
import { SttTabs } from '@stt-componentes/core';

const Exame = (props) => {
    const { setTabs, addTab, removeTab, tabs, activeTab, setActiveTab } = props;

    useEffect(() => {
        if (tabs.length === 0) {
            setTabs([{
                titulo: 'Pesquisa',
                conteudo: AbaPesquisa,
                key: 'Pesquisa',
                adicionarAba: addTab,
                permanente: true,
            }]);
        }
    }, []);

    return (
        <SttTabs
            abas={tabs}
            canClose={true}
            handleChangeAbaAtiva={setActiveTab}
            abaAtiva={activeTab}
            handleCloseTab={(indice) => removeTab(indice)}
            permanente={true}
            preload={false}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        tabs: state.exame.tabs,
        activeTab: state.exame.activeTab
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTabs: tabs => dispatch(setTabs(tabs)),
        addTab: tab => dispatch(addTab(tab)),
        removeTab: index => dispatch(removeTab(index)),
        setActiveTab: index => dispatch(setActiveTab(index))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Exame);