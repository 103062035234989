import TYPES from './types/exame';

const INITIAL_STATE = {
    filtros: null,
    atualizarBusca: false,
    laudoCopia: null,
    tabs: [],
    activeTab: '0',
    urlOhif: {},
    assinaturaDigital: null
};

const exame = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    console.log(state.urlOhif);
    

    switch (type) {
        case TYPES.SET_FILTROS:
            return {
                ...state,
                filtros: payload.parametros
            }

        case TYPES.RESET:
            return {
                ...state,
                filtros: payload.parametros
            };

        case TYPES.ATUALIZAR_BUSCA:
            return {
                ...state,
                atualizarBusca: payload.atualizar
            }

        case TYPES.COPIAR_LAUDO:
            return {
                ...state,
                laudoCopia: payload.laudoCopia
            }

        case TYPES.SET_TABS:
            return {
                ...state,
                tabs: payload.tabs
            }

        case TYPES.ADD_TAB:
            let { idAba } = payload.tab;
            // !some para evitar adição de aba repetida
            if (!state.tabs.some(a => a.idAba === idAba)) {
                return {
                    ...state,
                    tabs: [...state.tabs, payload.tab],
                    activeTab: (state.tabs.length).toString()
                }
            }
            return state;

        case TYPES.REMOVE_TAB:
            const i = payload.index[0];
            let newState = {
                ...state,
                tabs: [
                    ...state.tabs.slice(0, i),
                    ...state.tabs.slice(i + 1)
                ]
            };
            if (newState.tabs.length > 0) {
                newState.activeTab = (newState.tabs.length - 1).toString();
            } else {
                newState.activeTab = '0';
            }
            return newState;

        case TYPES.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: payload.index
            }

        case TYPES.SET_URL_OHIF:
            return {
                ...state,
                urlOhif: { ...state.urlOhif, [payload.id]: payload.url }
            }

        case TYPES.SET_DADOS_ASSINATURA_DIGITAL:
            return {
                ...state,
                assinaturaDigital: payload.dados
            }

        default:
            return state;
    }
}

export default exame;
