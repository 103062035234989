import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { makeStyles } from '@material-ui/core';
import { useQuery } from 'src/hooks';
import { SttCircularProgress, SttHeading, SttTranslateHook } from '@stt-componentes/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { ASSINATURA_DIGITAL } from 'src/common/Constants';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    erroContainer: {
        padding: theme.spacing(3),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));

const NeoID = () => {
    const classes = useStyles();
    const query = useQuery();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [authorizationCode, setAuthorizationCode] = useState(null);
    const [authorizationToken, setAuthorizationToken] = useState(null);
    const [appState, setAppState] = useState(null);
    const [codeVerifier, setCodeVerifier] = useState(null);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [msgErro, setMsgErro] = useState(null);
    const [codigoErro, setCodigoErro] = useState(null);
    const [configNeoId, setConfigNeoId] = useState(null);

    /**
     * Obtém os dados enviados através que parâmetros de URL
     */
    useEffect(() => {
        // Obtém o código de autorização
        setAuthorizationCode(query.get('code'));

        // Obtém o estado da aplicação
        setAppState(query.get('state'));

        // Obtém o code verifier
        setCodeVerifier(localStorage.getItem(ASSINATURA_DIGITAL.NEOID.CODE_VERIFIER));

        // Obtém as config de assinatura digital NeoID
        const config = localStorage.getItem(ASSINATURA_DIGITAL.NEOID.CONFIG);
        setConfigNeoId(JSON.parse(config));
    }, []);

    /**
     * Valida o state para garantir que é o mesmo enviado na solicitaçõa de autorização
     *  - Recomendado para evitar ataques CSRF
     */
    useEffect(() => {
        const storedState = localStorage.getItem(ASSINATURA_DIGITAL.NEOID.STATE);
        setNotAuthorized(appState && appState !== storedState);
    }, [appState]);

    /**
     * Faz a troca do authorizationCode pelo token de autorização
     */
    useEffect(() => {
        if (authorizationCode && codeVerifier) {
            const params = {
                grant_type: ASSINATURA_DIGITAL.NEOID.AUTHORIZATION_CODE,
                client_id: configNeoId.neoid.client_id,
                client_secret: configNeoId.neoid.client_secret,
                code: authorizationCode,
                code_verifier: codeVerifier,
                redirect_uri: configNeoId.neoid.redirect_uri
            }

            const payload = new URLSearchParams(params);

            axios
                .post(`${configNeoId.neoid.url_base}/token`, payload, { headers: { 'Content-type': 'application/x-www-form-urlencoded' } })
                .then((response) => {
                    const { data } = response;
                    setAuthorizationToken(data);
                })
                .catch(err => {
                    console.log(err);
                    const { response } = err;
                    let msg = strings.erroGenerico;
                    let codigo = HttpStatus.INTERNAL_SERVER_ERROR;
                    if (response) {
                        codigo = response.data.error;
                        msg = response.data.error_description;
                    }
                    setMsgErro(msg);
                    setCodigoErro(codigo);
                });
        }
    }, [authorizationCode, codeVerifier, configNeoId]);

    /**
     * Armazena o token para cesso pela página que iniciou o processo de assinatura
     */
    useEffect(() => {
        if (authorizationToken) {
            localStorage.setItem(ASSINATURA_DIGITAL.NEOID.AUTHORIZATION_TOKEN, JSON.stringify(authorizationToken));

            setTimeout(() => {
                fechar();
            }, 500);
        }
    }, [authorizationToken]);

    /**
     * Fecha a janela de interação com a API NEOID
     */
    const fechar = () => {
        window.close();
    }

    return (
        notAuthorized ?
            <Redirect replace to='/nao-autorizado' /> :
            <>
                {
                    codigoErro ?
                        <div className={classes.erroContainer}>
                            <SttHeading variant="h1">
                                <ErrorOutlineIcon />
                                Erro
                            </SttHeading>
                            <SttHeading variant="h4">
                                {msgErro}
                            </SttHeading>
                            <SttHeading variant="h5">
                                {`${strings.codigo}: ${codigoErro}`}
                            </SttHeading>
                        </div> :
                        <div className={classes.carregando}>
                            <SttCircularProgress />
                        </div>
                }
            </>
    );
}

export default NeoID;
